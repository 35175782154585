import { useTranslation } from 'react-i18next';
import {
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import * as Icon from 'react-bootstrap-icons';

const UserMenu = () => {
  const { t } = useTranslation();

  return (
    <>
      <MDBCard className="mb-4 mb-lg-0 sticky-top sticky-offset">
        <MDBCardBody className="p-0">
          <MDBListGroup>
            <MDBListGroupItem>
              <a href="/profile" className="d-flex justify-content-between align-items-center">
                <Icon.FilePersonFill className="me-2 blue" />
                <MDBCardText>{t("Profile")}</MDBCardText>
              </a>
            </MDBListGroupItem>
            <hr/>
            <MDBListGroupItem>
              <a href="/settings" className="d-flex justify-content-between align-items-center">
                <Icon.GearFill className="me-2 grey" />
                <MDBCardText>{t("Settings")}</MDBCardText>
              </a>
            </MDBListGroupItem>
            <hr/>
            <MDBListGroupItem>
              <a href="/payments" className="d-flex justify-content-between align-items-center">
                <Icon.CreditCard2FrontFill className="me-2 green" />
                <MDBCardText>{t("Payments")}</MDBCardText>
              </a>
            </MDBListGroupItem>
            <hr/>
            <MDBListGroupItem>
              <a href="/support" className="d-flex justify-content-between align-items-center">
                <Icon.LifePreserver className="me-2 red" />
                <MDBCardText>{t("Support")}</MDBCardText>
              </a>
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
export default UserMenu;