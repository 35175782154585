import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const Pagination = ({ items, itemsPerPage, itemOffset, handlePageClick, className }) => {
    const { t } = useTranslation();
    const pageCount = Math.ceil(items.length / itemsPerPage);

      return (
        <>
          <ReactPaginate
            className={`pagination justify-content-center ${className}`}
            breakLabel="..."
            nextLabel={`${t('Next')} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={`< ${t('Previous')}`}
            renderOnZeroPageCount={null}
          />
        </>
      );
};

export default Pagination;