import { useEffect, useState } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useParams, useNavigate } from 'react-router-dom';

import { axiosPrivate } from '../_api/axios';
import * as Icon from 'react-bootstrap-icons';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import { useTranslation } from 'react-i18next';

import useAuth from "../_hooks/useAuth";

import ErrorHandler from '../_helpers/ErrorHandler';

import { ProductWrapper } from '../_helpers/ProductWrapper';

import {
  MDBCol,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

const RePay = () => {
  const loadingContext = useLoadingContext();

  const { auth } = useAuth();

  // Temporary server name if none is selected
  const tmpServerName = 'Ghost Gaming Server';

  const params = useParams();
  const { t } = useTranslation();

  const [subscription, setSubscription] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [credit, setCredit] = useState(0);
  const [canBuy, setCanBuy] = useState(false);

  const [hasAgreed, setHasAgreed] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const [choosenServer, setChoosenServer] = useState({});
  const [choosenServerPackage, setChoosenServerPackage] = useState({});
  const [choosenServerName, setChoosenServerName] = useState(tmpServerName);
  const [gameType, setGameType] = useState(0);

  const navigate = useNavigate();

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');

    setBtnLoading(true);

    if(!hasAgreed) {
      setErrMsg(t("AcceptOurAgreement"));
      setBtnLoading(true);
      return;
    }

    if(choosenServerName === '') {
      setChoosenServerName(tmpServerName);
    }

    const sid = params?.server;
    const pid = choosenServer._id;

      try {
        const response = await axiosPrivate.post('/server/renew',
            JSON.stringify({ pid, sid, subscription, choosenServerPackage, gameType }),
            {
              headers: { 
                'Content-type': 'application/json',
                'Authorization': `Bearer ${auth?.accessToken}`,
              },
              withCredentials: true
            }
        );

        if (response?.status === 200) {
          navigate('/profile', { replace: true });
        }

        if (response?.status === 410) {
          setErrMsg(t("ThisProductIsSoldOut"));
        }

        setBtnLoading(true);

      } catch(err) {
        if (err.response?.status === 409 ||
            err.response?.status === 403 ||
            err.response?.status === 401) {
          setErrMsg(err);
        } else if(err.response?.status === 422) {
          setErrMsg(t("ThereWasAnError"));
        } else {
          setErrMsg(t("PaymentFailed"));
        }
        
        setBtnLoading(false);
      }
  }

  const toggleSubscription = (e) => {
    setSubscription(e.target.checked);
  }

  const toggleAgreed = (e) => {
    setHasAgreed(e.target.checked);
  }

  useEffect(() => {

    let isMounted = true;
      const getProduct = async (url) => {
        setIsLoading(true);
        setErrMsg('');

          try {
            const pid = params?.product;
            const paid = params?.productid;
            const sid = params?.server;

              const response = await axiosPrivate.post(url,
                  JSON.stringify({ sid, pid, paid }),
                  {
                    headers: { 
                      'Content-type': 'application/json',
                      'Authorization': `Bearer ${auth?.accessToken}`,
                    },
                    withCredentials: true
                  }
              );

              if (isMounted && !loadedData) {
                  setSubscription(response.data.server.subscription);
                  setChoosenServer(response.data.product);
                  setChoosenServerPackage(response.data.package);
                  setCredit(auth?.credit);

                    if(response.data.product.gametypes) {
                      setGameType(response.data.product.gametypes[0].egg);
                    } else {
                      setErrMsg(t("InvalidEntry"));
                    }

                    if((auth?.credit - response.data.package.price) < 0) {
                      setCanBuy(false);
                    } else {
                      setCanBuy(true);
                    }

                  setLoadedData(true);
                  setFetchError(null);
              }

              loadingContext.done();
          } catch(err) {
              if (isMounted) {
                  navigate('/profile', { replace: true });
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      getProduct(`${process.env.REACT_APP_BACKEND_URL}/server/getrenew`);

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, [fetchError, auth, params, choosenServer, choosenServerPackage, credit, loadedData, t, navigate, loadingContext]);

  return (
    <>
      <Header />
        <div id="header_static" className={`${choosenServer.headerclass} container-fluid product single center-bg`}>
          <div className="product_top d-none d-lg-block">
            <h1 className={`card-header ${choosenServer.iconclass}`}>
              { choosenServerPackage.titlelang !== undefined ? choosenServer.title : '' }
            </h1>
          </div>
        </div>
          <div className="container-fluid d-none d-md-block d-lg-block d-xl-block">
            <div className="row frontpage_banner justify-content-center mb-4">
              <ul className="list-group list-group-horizontal">
                <li className="list-group-item" key="1">
                  <Icon.ShieldLockFill className="icon" /> {t('DDoSProtection')}
                </li>
                <li className="list-group-item" key="2">
                  <Icon.CpuFill className="icon" /> {t('CPUUsed')}
                </li>
                <li className="list-group-item" key="3">
                  <Icon.HandIndexFill className="icon" /> {t('OneClickSetup')}
                </li>
                <li className="list-group-item" key="4">
                  <Icon.StopwatchFill className="icon" /> {t('InstantDelivery')}
                </li>
              </ul>
            </div>
          </div>

          <div className="container pull-down">
            <div className="row">
              <div className="product">

                <MDBRow>
                  <MDBCol>
                    <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">
                      <MDBBreadcrumbItem>
                        <a href='/'>{t("Home")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href="/game-servers">{t("GameServers")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href="/profile">{t("Profile")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>{choosenServerPackage.titlelang ? t(choosenServerPackage.titlelang) : '?'}</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="product-cards">
                  <MDBCol xl={4} lg={4} sm={12} xs={12} className="d-flex align-items-stretch mb-4">
                    { loadedData && !isLoading ? (
                        <ProductWrapper value={choosenServerPackage} size={12} noAnim={true} noBtn={true} noPrice={true} noBadge={true} key={choosenServer._id} />
                      ) : (
                        <div className="card card-body product d-flex">
                         <h3 className="card-header price">
                           {t("DoesNotExist")}
                         </h3>
                         <p>{t("DoesNotExistMsg")}</p>
                        </div>
                      )}
                  </MDBCol>
                  <MDBCol xl={4} lg={4} sm={12} xs={12} className="d-flex align-items-stretch mb-4">

                    <div className="card card-body product d-flex">
                     <h3 className="card-header price">
                       {t("Invoice")}
                     </h3>
                      <div className="container">
                        <p className="mb-2 fs-6">{t("Credit")} - {t('CurrencyFormat', { currency: credit })}</p>
                          <hr className="lg"/>
                        <div className="col-12 text-right">
                          <p className="m-3 fs-6">1 {t("AMonth")} - {t('CurrencyFormat', { currency: choosenServerPackage.price })}</p>
                        </div>
                          <hr/>
                        <div className="col-12 text-right">
                          <p className="m-3 fs-6">{t("Tax")} - {t('CurrencyFormat', { currency: ((choosenServerPackage.price * 1.25) - choosenServerPackage.price).toFixed(2) })}</p>
                        </div>
                          <hr/>
                        <div className="col-12 text-right">
                          <p className="m-3 fs-6">{t("Total")} - <span className="total fw-bold">{t('CurrencyFormat', { currency: choosenServerPackage.price })}</span></p>
                        </div>
                          <hr className="lg mb-2"/>
                          { canBuy ? (
                              <>
                                <div className="col-12">
                                  <p className="fs-6">{t("CreditLeft")} - {t('CurrencyFormat', { currency: (credit - choosenServerPackage.price) })}</p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-12">
                                  <p className="fs-6">{t("ChargeAccount")}, <a href="/charge">{t("ChargeHere").toLowerCase()}</a></p>
                                </div>
                              </>
                            )
                          }
                      </div>
                    </div>

                  </MDBCol>
                  <MDBCol xl={4} lg={4} sm={12} xs={12} className="d-flex align-items-stretch mb-2">
                    <div className="card card-body product d-flex flex-column payment-container">

                       <h3 className="card-header price">
                         {t("Configuration")}
                       </h3>
                        <ErrorHandler error={errMsg} />

                        <div className="d-inline-block align-items-center mt-1">
                          { loadedData ? (
                            <>
                              <MDBCol xl={12} lg={12} sm={12} xs={12} className="text-center">
                                  <p>{t("RenewServerMsg")}.</p>
                                  <hr className="mb-3" />
                                <input
                                  className="form-check-input me-3 mt-1"
                                  type="checkbox"
                                  onChange={toggleSubscription}
                                  checked={subscription}
                                  id="subscriptionCheck"
                                />

                                <label className="form-check-label mb-2" htmlFor="subscriptionCheck">
                                  {t("MonthlySubscription")}
                                </label>

                                 <hr className="mb-3" />
                                <input
                                  className="form-check-input me-3 mt-3"
                                  type="checkbox"
                                  onChange={toggleAgreed}
                                  id="registerCheck"
                                  aria-describedby="registerCheckHelpText"
                                />

                                <label className="form-check-label mb-2" htmlFor="registerCheck">
                                  {t("AgreeTermsStart")} <a href="/terms-of-service">{t("TermsOfService").toLowerCase()}.</a><br /> {t("AgreeTermsEnd")} <a href="/privacy-policy">{t("PrivacyPolicy").toLowerCase()}.</a>
                                </label>

                                <button
                                  type="button"
                                  disabled={!canBuy || !loadedData || !hasAgreed || btnLoading}
                                  className="btn btn-success w-100"
                                  onClick={handleBuySubmit}
                                >
                                  {t("RenewServer")}
                                </button>
                              </MDBCol>
                            </>
                          ) : (
                            <p className="red">{t("ThisIsNotAnOption")}!</p>
                          )}
                        </div>

                    </div>
                  </MDBCol>
                </MDBRow>

              </div>
            </div>
          </div>
      <Footer />
    </>
  )
};

export default RePay;