/**
 * ---------------------------------------------------
 * Available eggs
 * ---------------------------------------------------
 * Loads a choosen egg, and returning the custom 
 * style for it.
 * ---------------------------------------------------
 */

const Eggs = egg => { 

    let currentBg = '',
        currentGameName = '';

        // Choosing the right egg template
        switch(egg) {
          default:
            currentGameName = 'Unknown Egg';
            currentBg = 'default';
            break;

          case 1:
            currentGameName = 'Minecraft: Sponge (SpongeVanilla)';
            currentBg = 'minecraft-mini';
            break;

          case 2:
            currentGameName = 'Minecraft: Vanilla';
            currentBg = 'minecraft-mini';
            break;

          case 3:
            currentGameName = 'Minecraft: BungeeCord';
            currentBg = 'minecraft-mini';
            break;

          case 4:
            currentGameName = 'Minecraft: Paper';
            currentBg = 'minecraft-mini';
            break;

          case 5:
            currentGameName = 'Forge Minecraft';
            currentBg = 'minecraft-mini';
            break;

          case 7:
            currentGameName = 'Ark: Survival Evolved';
            currentBg = 'ark';
            break;

          case 9:
            currentGameName = 'CS:GO';
            currentBg = 'csgo-mini';
            break;

          case 19:
            currentGameName = 'Rust';
            currentBg = 'rust-mini';
            break;

          case 17:
            currentGameName = 'Mordhau';
            currentBg = 'mordhau-mini';
            break;

          case 18:
            currentGameName = 'JMusicBot';
            currentBg = 'musicbot-mini';
            break;
        }

                return { currentGameName, currentBg };
}


/* Server Statuses */
const Status = status => {

    let currentStatusIcon = '',
        currentStatusTip = '';

        // Choosing right status icon
        switch(status) {
            case 'offline':
            case 'starting':
            case 'installing':
            case 'running':
            currentStatusIcon = status;
            currentStatusTip = status;
            break;

            default:
            currentStatusIcon = 'offline';
            currentStatusTip = 'Offline';
            break;
        }

            return { currentStatusIcon, currentStatusTip };
}

export { Eggs, Status };