import axios from '../_api/axios';
import useAuth from './useAuth';


/**
 * ---------------------------------------------------
 * Handles refresh token.
 * ---------------------------------------------------
 * To ensure a secure persisting login. 
 * If the user have choosen to save their credentials
 * the system will automatic refresh their access
 * token on every page switch, but only if they are
 * logged in.
 * This function acts as a middleware.
 * ---------------------------------------------------
 * @params setAuth
 * @output Access
 * @type JSON
 * ---------------------------------------------------
 */
const useRefreshToken = () => {
    const { setAuth } = useAuth();
    
    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(response.data.email);
            // console.log(response.data.userid);
            // console.log(response.data.roles);
            // console.log(response.data.accessToken);
            // console.log(response.data.credit);

            return {
                ...prev,
                email: response.data.email,
                userid: response.data.userid,
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                credit: response.data.credit
            }
        });
            
            return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;