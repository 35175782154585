import { useState, useEffect } from "react";

/**
 * ---------------------------------------------------
 * Lazy Image Load
 * ---------------------------------------------------
 * Lazy loads out images, so that the user
 * not have to wait for a laggy loading
 * ---------------------------------------------------
 */

// Loading the image with a promise
const loadImage = (src: string): Promise<string> =>
    new Promise((resolve, reject) => {
        const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = () => reject(new Error("Could not load image!"));
});

const GhostLazyImage = (props) => {
    const split = props.src.split('.');
    const newSrc = split[0] + '_thumb.' + split[1];
    const [src, setSrc] = useState(`${process.env.REACT_APP_PANEL_URL}/_assets/${newSrc}`);
    const [loaded, setLoaded] = useState(false);

        useEffect(() => {
            const loadImageAsync = async (src) => {
                await loadImage(require(`../../public/_assets/${src}`))
                    .then((src) => {
                        setSrc(src);
                        setLoaded(true);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };

                if(!loaded) loadImageAsync(props.src);
        }, [loaded, props.src]);

            return (
                <>
                    <img 
                        src={src ? src : `${process.env.REACT_APP_PANEL_URL}`}
                        loading="lazy"
                        alt="Page"
                        onLoad={() => setLoaded(true)}
                        className={`source ${loaded ? "loaded" : ""}`}
                    />
                </>
            );
}

export default GhostLazyImage;