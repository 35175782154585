import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { itemsPrPage } from '../_helpers/Utils';
import {
  MDBDropdown,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';

const PrPageSelect = ({ className, handlePageClick }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState('hide');

    // Toggles menu on mobiles when hover is deactivated
    const openMenu = async (e) => {
      e.preventDefault();
        setShowMenu(showMenu === 'hide' ? 'show' : 'hide');
    }

    const onChange = (e) => {
      e.preventDefault();
        window.localStorage.setItem('itemsPrPage', parseInt(e.target.innerHTML));
          // Resets the current page
          e.selected = 0;
            handlePageClick(e);
    }

      return (
        <>
          <MDBDropdown className={`dropdown-wrapper pull-right ${className}`}>
            <a href="/" onClick={openMenu} tag='a' aria-current='page' className='game-servers'>
               {t("ItemsPrPage")} <Icon.CaretDownFill className="fs-7" />
            </a>
            <ul className={`dropdown-menu animation game-servers fade-up mw3rem text-center ${showMenu}`}>
              <MDBDropdownItem link key="0" onClick={onChange} className={(itemsPrPage() === 4 ? 'selected' : '')} href="/">4</MDBDropdownItem>
              <MDBDropdownItem link key="1" onClick={onChange} className={(itemsPrPage() === 8 ? 'selected' : '')} href="/">8</MDBDropdownItem>
              <MDBDropdownItem link key="2" onClick={onChange} className={(itemsPrPage() === 16 ? 'selected' : '')} href="/">16</MDBDropdownItem>
              <MDBDropdownItem link key="3" onClick={onChange} className={(itemsPrPage() === 32 ? 'selected' : '')} href="/">32</MDBDropdownItem>
              <MDBDropdownItem link key="4" onClick={onChange} className={(itemsPrPage() === 64 ? 'selected' : '')} href="/">64</MDBDropdownItem>
            </ul>
          </MDBDropdown>
        </>
      );
};

export default PrPageSelect;