import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../_hooks/useAuth';


/**
 * ---------------------------------------------------
 * Handles routes with authentication overlay
 * ---------------------------------------------------
 * This ensures a secure overlay to the different
 * routes, acts as a middleware and is used to avoid
 * unwanted activity in different parts of the platform.
 * If user role, matches the DB, it allows the user to
 * load the content.
 * Otherwise it redirects the user to the login or 
 * the unauthorized page.
 * ---------------------------------------------------
 * @params auth, location
 * @output security/access/routes
 * ---------------------------------------------------
 */

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    
    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.accessToken //changed from user to accessToken to persist login after refresh
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;