// import { useTranslation } from 'react-i18next';
import {
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import * as Icon from 'react-bootstrap-icons';

const DefaultFrontMenu = () => {
  // const { t } = useTranslation();
  
  return (
    <>
      <MDBCard className="mb-4 mb-lg-0">
        <MDBCardBody className="p-0">
          <MDBListGroup>
            <MDBListGroupItem>
              <a href="/menu-1" className="d-flex justify-content-between align-items-center">
                <Icon.FilePersonFill className="me-2" />
                <MDBCardText>Menu 1</MDBCardText>
              </a>
            </MDBListGroupItem>
            <hr/>
            <MDBListGroupItem>
              <a href="/menu-2" className="d-flex justify-content-between align-items-center">
                <Icon.GearFill className="me-2" />
                <MDBCardText>Menu 2</MDBCardText>
              </a>
            </MDBListGroupItem>
            <hr/>
            <MDBListGroupItem>
              <a href="/menu-3" className="d-flex justify-content-between align-items-center">
                <Icon.CreditCard2FrontFill className="me-2" />
                <MDBCardText>Menu 3</MDBCardText>
              </a>
            </MDBListGroupItem>
            <hr/>
            <MDBListGroupItem>
              <a href="/menu-4" className="d-flex justify-content-between align-items-center">
                <Icon.LifePreserver className="me-2" />
                <MDBCardText>Menu 4</MDBCardText>
              </a>
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
export default DefaultFrontMenu;