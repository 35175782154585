import { BarLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import Logo from '../_assets/images/licon_trans.png';

const LoadingScreen = () => {
    const { t } = useTranslation();

        return (
            <>
                <div className="loader">
                    <img src={Logo} alt="Logo" style={{ width: '90px', opacity: '0.5' }} />
                        <BarLoader
                        className="bar"
                        color="#009b49"
                        height={6}
                        speedMultiplier={0.8}
                        width={150}
                        />
                        <p>{t("Loading")}</p>
                </div>
            </>
        );
};
export default LoadingScreen;