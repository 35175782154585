import axios from '../_api/axios';
import useAuth from './useAuth';

const useLogout = () => {
    const { setAuth, setIsLoggedIn } = useAuth();

    const logout = async () => {
        setAuth({});
        setIsLoggedIn(false);

        await localStorage.removeItem('isLoggedIn');

            try {

                await axios('/logout', {
                    withCredentials: true
                });

            } catch (err) {
                console.error(err);
            }
    }

    return logout;
}

export default useLogout