import { useTranslation } from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import { documentHeight } from '../_helpers/Utils';
import Header from '../_components/Header';
import Footer from '../_components/Footer';

import UserMenu from '../_components/UserMenu';

const Support = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product mini">
          <div className="product_top d-none d-lg-block">
            <h1>{t("Support").toUpperCase()}</h1>
          </div>
        </div>
        <section>
          <MDBContainer className="py-4">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4 breadcrumb danger">
                  <MDBBreadcrumbItem>
                    <a href='/'>{t("Home")}</a>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>
                    {t("Support")}
                  </MDBBreadcrumbItem>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3">
                <UserMenu />
              </MDBCol>

              <MDBCol lg="9" style={{ minHeight: `${documentHeight()}px` }}>
                <MDBCard className="mb-4">
                  <MDBCardBody>

                    <MDBRow>
                      <MDBCol sm="12">
                        <a href="https://discord.gg/CzdF799GRn" className="form-control btn-success">
                          <Icon.LifePreserver className="fs-5 me-2 icon" />{t("CreateSupportTicket")}
                        </a>
                        {/*<a href="/support/view" className="form-control btn-grey mt-2">
                          <Icon.BinocularsFill className="fs-5 me-2 icon" />{t("ViewSupportTickets")}
                        </a>*/}
                        {/*<a href="/faq" className="form-control btn-warning mt-2">
                          <Icon.QuestionDiamondFill className="fs-5 me-2 icon" />{t("FrequentlyAskedQuestions")}
                        </a>*/}
                        <a href="/wiki" className="form-control btn-grey mt-2">
                          <Icon.CollectionFill className="fs-5 me-2 icon" />{t("WikipediaPage")}
                        </a>
                      </MDBCol>
                    </MDBRow>

                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

      <Footer />

    </>
  )
};

export default Support;