import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import AnimatedCounter from '../_helpers/AnimatedCounter';
import SiteLogo from "../_assets/images/slogo.png";

import { useTranslation } from "react-i18next";

import * as Icon from "react-bootstrap-icons";
import useLogout from "../_hooks/useLogout";
import useAuth from "../_hooks/useAuth";

import Dropdown from "react-bootstrap/Dropdown";

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
} from 'mdb-react-ui-kit';

import ROLES from "../_helpers/Roles";

const Header = () => {
  const { auth } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();

  const [ showBasic, setShowBasic ] = useState(true);
  const [ showMenu, setShowMenu ] = useState('hide');

  const { t } = useTranslation();

  // Capitalize first letter
  // const ucFirst = (str) => {
  //   return  str !== undefined ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  // };

  // Check if logged in
  const hasAuth = auth?.roles && auth?.accessToken;

  // Signout function
  const signOut = async (e) => {
    e.preventDefault();
      await logout();
        await navigate('/login', { replace: true });
  };

  // Open menu function
  const openMenu = async (e) => {
    e.preventDefault();
      setShowMenu(showMenu === 'hide' ? 'show' : 'hide');
  };

  return (
    <>
      <header>
        <div className="d-flex justify-content-center" id="header">
          <div className="row col-12 col-xs-12 col-sm-12 col-lg-9">
            <MDBNavbar expand='md'>
              <MDBContainer>
                <MDBNavbarBrand href='/' className="header_logo">
                  <img src={SiteLogo} alt="Site logo" id="header_logo" />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                  aria-controls='navbarSupportedContent'
                  aria-expanded='true'
                  aria-label='Toggle navigation'
                  onClick={() => setShowBasic(!showBasic)}
                >
                   <Icon.ThreeDots />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={showBasic}>
                  <div className="d-flex w-100">
                    <MDBNavbarNav className='mr-auto topMainMenu flex-fill align-self-center'>

                      <MDBNavbarItem>
                        <a href="/overview" className='nav-link'>
                          {t("GameServers")}
                        </a>
                      </MDBNavbarItem>


                      <MDBNavbarItem>
                        <MDBNavbarLink href='/status'>{t("ServerStatus")}</MDBNavbarLink>
                      </MDBNavbarItem>

                      {/*<MDBNavbarItem>
                        <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                          Disabled
                        </MDBNavbarLink>
                      </MDBNavbarItem>*/}
                    </MDBNavbarNav>


                  <MDBNavbarNav className='mr-auto topSubMenu align-self-center'>

                    <MDBNavbarItem className="menuDiscordBtn align-self-center d-none d-md-block d-lg-block">
                      <a href="https://discord.gg/CzdF799GRn" target="_new">
                        Discord <Icon.Discord />
                      </a>
                    </MDBNavbarItem>
                    {hasAuth ? (
                      <>
                        <MDBNavbarItem className="menuCreditBtn align-self-center d-inline-block d-md-inline-block d-lg-inline-block">
                          <a href="/payments">
                            <AnimatedCounter from={0} to={auth?.credit} /> <Icon.CreditCard2FrontFill />
                          </a>
                        </MDBNavbarItem>
                        <MDBNavbarItem className="dropdown-wrapper">

                          <div className="dropdown">
                            <a href="/" onClick={openMenu} data-bs-target="#nav" data-bs-toggle="dropdown" className="homeActionBtn dropdown-toggle show btn btn-success">
                              {t("UserMenu")}
                            </a>

                            <div className={`fade-up dropdown-menu ${showMenu}`} id="nav">
                              {/*<Dropdown.Item href="/panel" onClick={toControlPanel}>
                                <Icon.DeviceHddFill className="me-1 grey" /> {t("ServerPanel")}
                              </Dropdown.Item>*/}
                              <Dropdown.Item href="/payments">
                                <Icon.CreditCard2FrontFill className="me-1 green" /> {t("Payments")}
                              </Dropdown.Item>
                              <Dropdown.Item href="/profile">
                                <Icon.PersonBadgeFill className="me-1 blue" /> {t("Profile")}
                              </Dropdown.Item>
                              <Dropdown.Item href="/settings">
                                <Icon.GearFill className="me-1 grey" /> {t("Settings")}
                              </Dropdown.Item>
                              <Dropdown.Item href="/support">
                                <Icon.LifePreserver className="me-1 red" /> {t("Support")}
                              </Dropdown.Item>
                              {auth?.roles && auth?.roles.includes(ROLES.Admin) ? (
                                <Dropdown.Item href="/admin/overview">
                                  <Icon.StarFill className="me-1 gold" /> {t("AdminPanel")}
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                              <Dropdown.Item href="/logout" onClick={signOut}>
                                <Icon.DoorOpenFill className="me-1 brown" /> {t("Logout")}
                              </Dropdown.Item>

                            </div>
                          </div>

                        </MDBNavbarItem>
                      </>
                    ) : (
                      <MDBNavbarItem>
                        <a
                          href="/login"
                          className="actionBtn btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 order-lg-3 hover-fade-out fade-page glow"
                        >
                          {t("ClientArea")} <Icon.HddRackFill />
                        </a>
                      </MDBNavbarItem>
                    )}
                   </MDBNavbarNav>
                  </div>
                </MDBCollapse>
              </MDBContainer>
            </MDBNavbar>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
