  const SelectStyle = {
      control: (provided) => ({
        ...provided,
        outline: '1px #009b49 solid',
      }),
      option: base => ({
        // ...base,
        cursor: 'pointer',
        padding: 5,
        border: 0,
        "&:hover": {
          backgroundColor: "#141728"
        },
      })
  };

  export default SelectStyle;