// import React, { useState, useEffect } from 'react';
// import { useLoadingContext } from 'react-router-loading';
import SiteLogo from '../_assets/images/logo.png';
// import * as Icon from 'react-bootstrap-icons';

import GhostLazyBgImage from '../_helpers/GhostLazyBgImage';

// import { useTranslation } from 'react-i18next';
// import { axiosPrivate } from '../_api/axios';

// import useAuth from '../_hooks/useAuth';

/** OVERVIEW FUNCTION */
const AdminOverview = () => {
  // const loadingContext = useLoadingContext();
  // const [data, setData] = useState([]);
  // const [fetchError, setFetchError] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  // const { t } = useTranslation();
  // const { auth } = useAuth();

    // useEffect(() => {
    //   let isMounted = true;

    //     const getOverviewData = async (url) => {
    //       setIsLoading(true);
    //         try {
    //           const response = await axiosPrivate.post(url, {
    //              headers: {
    //                 'Content-type': 'application/json',
    //                 'Authorization': `Bearer ${auth?.accessToken}`,
    //              },
    //              withCredentials: true
    //           });

    //             if (isMounted) {
    //                 setData(response.data.requests);
    //                 console.log(response.data.requests);
    //                 setFetchError(null);
    //             }

    //             loadingContext.done();
    //         } catch(err) {

    //             if (isMounted) {
    //                 setFetchError(err.message);
    //                 setData([]);
    //             }
    //         } finally {
    //             isMounted && setIsLoading(false);
    //         }
    //     }

    //     const cleanUp = () => {
    //         isMounted = false;
    //     }

    //     return cleanUp;
    // }, [auth?.accessToken]);

  return (
    <>
      <GhostLazyBgImage />
      <div className="pt-5">
        <a href="/" className="d-flex justify-content-center">
          <img src={SiteLogo} alt="contacts" id="login_logo" />
        </a>

        <div className="container col-6 col-xs-12 col-md-12 col-lg-6 col-xl-6">
          <div className="text-right text-white">Updates In: s</div>
        </div>
        <div className="container col-6 col-xs-12 col-md-12 col-lg-6 col-xl-6 mt-5 overview-content">
          <div className="text-right">Total Requests: </div>
          <div className="row scrollwindow p500">

          </div>
        </div>

        <div className="container col-6 col-xs-12 col-md-12 col-lg-6 col-xl-6 mt-5 overview-content">
          <div className="text-right">Total Errors: </div>
          <div className="row scrollwindow p500">

          </div>
        </div>

      </div>
    </>
  );
};

export default AdminOverview;
