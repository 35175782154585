import { useTranslation } from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';

const ErrorHandler = (props) => {
  const { t } = useTranslation();

  if(!props || !props.error) return;

  let content;
    if(props.error?.response) {
      if (props.error.response?.status === 401) {
        content = t("ThereWasAnError");
      } else if (props.error.response?.status === 403) {
        content = t("InvalidToken");
      } else {
        content = t("ThereWasAnError");
      }
    } else {
      content = props.error;
    }

      return (
        <p className="alert alert-danger errmsg" aria-live="assertive">
          <Icon.ExclamationTriangleFill className="me-2 fs-3" /> {content}!
        </p>
      );
}

export default ErrorHandler;