import React, { useEffect, useState } from 'react';
import { useLoadingContext } from 'react-router-loading';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
} from 'mdb-react-ui-kit';
import AnimatedCounter from '../_helpers/AnimatedCounter';
import * as Icon from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as sanitizeHtml from 'sanitize-html-react';
import { useTranslation } from 'react-i18next';

import { axiosPrivate } from '../_api/axios';

import Header from '../_components/Header';
import Footer from '../_components/Footer';
import useAuth from '../_hooks/useAuth';

import { Eggs, Status } from '../_helpers/Eggs';
import {
  toHHMMSS,
  bytesToSize,
  formatDate,
  strShorten,
  ucFirst,
  hidePhone,
  removeFadeOut,
  documentHeight
} from '../_helpers/Utils';
import ErrorHandler from '../_helpers/ErrorHandler';
import UserMenu from '../_components/UserMenu';

const Profile = () => {
  const loadingContext = useLoadingContext();
  const { t } = useTranslation();
  const { auth } = useAuth();

  const [userData, setUserData] = useState(null);
  const [serverCount, setServerCount] = useState(null);
  const [news, setNews] = useState([]);

  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    // Confirm server delete
    const cancelServer = (e, identifier, product, productid) => {
      e.preventDefault();
        confirmAlert({
          title: t("AreYouSure") + ' ?',
          message: t("WantToDeleteServer"),
          buttons: [
            {
              label: t("Yes"),
              className: 'btn btn-danger',
              onClick: () => {
                const cancelServer = async (url) => {
                  try {
                    const response = await axiosPrivate.post(url, 
                      JSON.stringify({ identifier, product, productid }),
                        {
                          headers: {
                            'Content-type': 'application/json',
                            'Authorization': `Bearer ${auth?.accessToken}`,
                        },
                        withCredentials: true
                    });

                    if(response.data === true) {
                      // Removing div card from DOM
                      removeFadeOut(document.getElementById(identifier), 1000);

                      // When faded out, decrease server count, for UI to adjust
                      setServerCount(serverCount-1);
                    }

                  } catch(err) {
                    console.error(err);
                  } finally {

                  }
                }

                cancelServer(`${process.env.REACT_APP_BACKEND_URL}/server/cancel/${identifier}`);
              }
            },
            {
              label: t("No"),
              className: 'btn btn-success',
              onClick: () => { return false; }
            }
          ]
        });
    }

    useEffect(() => {
      let isMounted = true;
        const getUserInfo = async (url) => {
          setIsLoading(true);
            try {
              const response = await axiosPrivate.get(url, {
                 headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                 },
                 withCredentials: true
              });

                if (isMounted) {
                    setUserData(response.data);
                    setServerCount(response.data.servers.length);
                    setNews(response.data.news);
                    console.log(response.data);
                    setErrMsg(null);
                }

                loadingContext.done();
            } catch(err) {
                console.error(err);
                setUserData(null);
                setServerCount(0);
                setNews([]);
            } finally {
                setIsLoading(false);
                isMounted = false;
            }
        }

        getUserInfo(`${process.env.REACT_APP_BACKEND_URL}/user/profile`);

        const cleanUp = () => {
            isMounted = false;
        }

        return cleanUp;
    }, [errMsg, auth?.accessToken, t, loadingContext]);

      return (
        !isLoading &&
        userData &&
          <>
            <Header />
              <div id="header_static" className="profile-bg container-fluid product mini">
                <div className="product_top d-none d-lg-block">
                  <h1>{t("Profile").toUpperCase()}</h1>
                </div>
              </div>
              <section>
                <MDBContainer className="py-4">
                  <MDBRow>
                    <MDBCol>
                      <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4 breadcrumb danger">
                        <MDBBreadcrumbItem>
                          <a href='/'>{t("Home")}</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          {t("Profile")}
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol lg="3">
                      <MDBCard className="mb-4">
                        <MDBCardBody className="text-center wallet">
                          <p className="text-muted mb-1 mt-5">{t("Credit")}</p>
                          <p className="text-muted mb-4">
                            <b className="text-white">
                              {
                                !isLoading && 
                                userData 
                                  ? <><AnimatedCounter from={0} to={userData.credit} /> kr</>
                                  : t("LoadingContent")
                              }
                            </b>
                          </p>
                          <div className="d-flex justify-content-center mb-2">
                            <a href="/charge" className="btn btn-success">
                              <Icon.CreditCard2FrontFill className="me-1" /> 
                                {t("ChargeHere")}
                            </a>
                          </div>
                        </MDBCardBody>
                      </MDBCard>

                      <UserMenu />

                    </MDBCol>

                    <MDBCol lg="9" style={{ minHeight: `${documentHeight()}px` }}>
                      <MDBRow>
                        <MDBCol sm="12" md="12" lg="12" xl="8" className="mb-4">
                          <ErrorHandler error={errMsg} />
                          <MDBCard className="mb-md-0">
                            <MDBCardBody>

                              <MDBRow>
                                <MDBCol sm="3">
                                  <MDBCardText>{t("Email")}</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="9">
                                  <MDBCardText className="text-white">{userData ? userData.email : ''}</MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              { 
                                userData.phone !== undefined  
                                  ? <>
                                      <MDBRow>
                                        <MDBCol sm="3">
                                          <MDBCardText>{t("Phone")}</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                          <MDBCardText className="text-white">{userData ? hidePhone(userData.phone) : ''}</MDBCardText>
                                        </MDBCol>
                                      </MDBRow>
                                      <hr />
                                    </>
                                  : ''
                              }
                              <MDBRow>
                                <MDBCol sm="3">
                                  <MDBCardText>{t("Country")}</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="9">
                                  <MDBCardText className="text-white">{userData ? ucFirst(userData.country) : ''}</MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="3">
                                  <MDBCardText>{t("Created")}</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="9">
                                  <MDBCardText className="text-white">{userData ? formatDate(userData.created) : ''}</MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              { 
                                userData.discordValidated  
                                  ? <>
                                      <hr />
                                      <MDBRow>
                                        <MDBCol sm="3">
                                          <MDBCardText>{t("Discord")}</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                          <MDBCardText className="text-success">{userData ? userData.discord : ''}</MDBCardText>
                                        </MDBCol>
                                      </MDBRow>
                                    </>
                                  : ''
                              }
                            </MDBCardBody>
                          </MDBCard>
                            <MDBRow id="serverOverview">
                              { 
                                isLoading 
                                  ? <div className="col-md-8 mb-4">
                                      <p className="statusMsg">
                                       <p>
                                        {t("LoadingServers")}
                                       </p>
                                      </p>
                                    </div> 
                                  : '' 
                              }
                              { 
                                !isLoading &&
                                serverCount === 0 
                                  ? <div className="col-md-12 mt-4">
                                      <p className="statusMsg">
                                        <i>
                                          {t("NoServersFound")}.
                                        </i>
                                      </p>
                                    </div> 
                                  : ''
                              }
                              { 
                                !isLoading &&
                                userData &&
                                userData.servers &&
                                userData.servers.length !== 0 &&
                                userData.servers.map((value, index) => {
                                  // Choosing right game template for the egg
                                  let currentEgg = Eggs(value.server_egg),
                                      currentBg = currentEgg.currentBg,
                                      currentGameName = currentEgg.currentGameName,

                                      currentEggStatus = Status(value.server_status),
                                      currentStatusIcon = currentEggStatus.currentStatusIcon,
                                      currentStatusTip = currentEggStatus.currentStatusTip,

                                      // Server Stats
                                      usageRamBytesToMb = ((value.server_usage.usage_memory / 1024) / 1024),
                                      usageRam = (usageRamBytesToMb * 100 / value.server_limits.memory).toFixed(2),

                                      usageCpu = (value.server_usage.usage_cpu * value.server_limits.cpu / value.server_limits.cpu).toFixed(2),

                                      usageDiskBytesToMb = ((value.server_usage.usage_disk / 1024) / 1024),
                                      usageDisk = (usageDiskBytesToMb * 100 / value.server_limits.disk).toFixed(2),

                                      serverCreated = formatDate(value.server_created),
                                      serverExpires = formatDate(value.server_expires),

                                      serverUptime = value.server_usage.usage_uptime !== 0 
                                                      ? toHHMMSS(value.server_usage.usage_uptime) 
                                                      : 0,
                                      networkUp = value.server_usage.usage_network_up !== 0 
                                                    ? bytesToSize(value.server_usage.usage_network_up) 
                                                    : 0,
                                      networkDown = value.server_usage.usage_network_down !== 0 
                                                      ? bytesToSize(value.server_usage.usage_network_down) 
                                                      : 0;

                                        return <div className={`mt-4 col-${serverCount <= 1 ? 12 : 6}`} id={value.server_identifier} key={value.server_id}>
                                                <MDBCard className="mb-md-0 mb-3 servercard">
                                                  <MDBCardBody className={currentBg}>
                                                    { 
                                                      value.server_suspended === true && value.server_expired !== true 
                                                        ? <div className="suspended">
                                                            <p className="center">
                                                              <Icon.ExclamationTriangle className="icon" />{t("ServerSuspended")}!<br/>
                                                              <a href={`/support/${value.server_identifier}`}>{t("ContactSupport")}</a>
                                                            </p>
                                                          </div> 
                                                        : ''
                                                    }
                                                    { 
                                                      value.server_expired === true 
                                                        ? <div className="suspended">
                                                            <div className="center">
                                                              <Icon.ExclamationTriangle className="icon" />
                                                              <div className="d-inline-block">
                                                                <p className="text-white mb-0">{t("ServerMissingPayment")}!</p>
                                                                <p className="text-white mb-0">{t("DeletesIn")}:</p>
                                                                <p className="text-white mb-0">{toHHMMSS(value.server_deletes)}</p>
                                                                <a href={`/pay/${value.server_id}/${value.server_pid}/${value.server_paid}`}>{t("PayHere")}</a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        : ''
                                                    }
                                                    <div className="mb-3 col-9">
                                                      <span className="text-primary font-italic">{strShorten(currentGameName, (serverCount <= 1 ? 60 : 24))}</span>
                                                      <Icon.CircleFill className={`icon ${currentStatusIcon} ms-2`} data-for="server-status-1" data-tip={currentStatusTip}/>
                                                      <br/>
                                                      <h3 className="mt-2 mb-0 text-muted">{strShorten(value.server_name, (serverCount <= 1 ? 60 : 24))}</h3>
                                                      {/*<h3 className="mt-2 mb-0 text-muted">{strShorten(value.server_description)}</h3>*/}
                                                    </div>
                                                    <MDBCardText className="mb-1">
                                                      {t("CPUUsage")} <i className="font-small text-muted">( {Math.round(value.server_limits.cpu / 100)} vCPU )</i> - <small className="text-muted">{usageCpu}%</small></MDBCardText>
                                                    <MDBProgress className="rounded">
                                                      <MDBProgressBar width={usageCpu} valuemin={0} valuemax={100} />
                                                    </MDBProgress>

                                                    <MDBCardText className="mt-2 mb-1">
                                                      {t("RAMUsage")} <i className="font-small text-muted">( {bytesToSize(value.server_usage.usage_memory)} / {Math.round(value.server_limits.memory / 1024)} Gb )</i> - <small className="text-muted">{usageRam}%</small></MDBCardText>
                                                    <MDBProgress className="rounded">
                                                      <MDBProgressBar width={usageRam} valuemin={0} valuemax={100} />
                                                    </MDBProgress>

                                                    <MDBCardText className="mt-2 mb-1">
                                                      {t("DiskUsage")} <i className="font-small text-muted">( {bytesToSize(value.server_usage.usage_disk)} / {Math.round(value.server_limits.disk / 1024)} Gb )</i> - <small className="text-muted">{usageDisk}%</small>
                                                    </MDBCardText>
                                                    <MDBProgress className="rounded">
                                                      <MDBProgressBar width={usageDisk} valuemin={0} valuemax={100} />
                                                    </MDBProgress>
                                                    <p className="pull-to-footer">
                                                      <Icon.ClockHistory className="icon me-2" />
                                                      <small className="text-muted">{serverCreated}</small>
                                                      <br />
                                                      <Icon.ArrowRight className="icon me-2 text-disabled" />
                                                      <small className="text-muted">{serverExpires}</small>
                                                    </p>

                                                    { 
                                                      serverUptime !== 0 ||
                                                      networkUp !== 0 ||
                                                      networkDown !== 0 
                                                        ? <div className="pull-top-right text-right">
                                                            <p className="m-0">
                                                              <i className="uptime me-1">{serverUptime}</i>
                                                              <Icon.ClockFill className="icon uptime" />
                                                            </p>

                                                            <p className="m-0 uptime">
                                                              <i className="uptime me-1 fs-7">{networkDown}</i>
                                                              <Icon.CloudArrowDownFill className="icon uptime fs-8" />
                                                            </p>
                                                            
                                                            <p className="m-0 uptime">
                                                              <i className="uptime me-1 fs-7">{networkUp}</i>
                                                              <Icon.CloudArrowUpFill className="icon uptime fs-8" />
                                                            </p>
                                                          </div>
                                                        : ''
                                                    }

                                                    <ul className="nav float-end">
                                                      <li className="nav-link gameserver">
                                                        <a 
                                                          href={`${process.env.REACT_APP_PANEL_URL}/server/${value.server_identifier}`}
                                                          target="_new"
                                                          className={value.server_suspended === false && value.server_expired !== true ? 'text-white nbl' : 'disabled'}
                                                          data-for="server-panel-1"
                                                          data-tip="Panel"
                                                        >
                                                          <Icon.CpuFill className="icon" />
                                                        </a>
                                                      </li>
                                                      <li className="nav-link accept">
                                                        <a 
                                                          href={`connect://${value.server_ip}:${value.server_port}`}
                                                          className={value.server_suspended === false && value.server_expired !== true && value.server_status === 'started' ? 'text-white nbl' : 'text-white nbl disabled'}
                                                          data-for="server-connect-1"
                                                          data-tip="Connect"
                                                        >
                                                          <Icon.PlugFill className="icon" />
                                                        </a>
                                                      </li>
                                                      <li className="nav-link decline">
                                                        <a
                                                          href={`/server/cancel/${value.server_identifier}`}
                                                          onClick={(e, identifier) => cancelServer(e, value.server_identifier, value.server_pid, value.server_paid)}
                                                          className={value.server_suspended === false && value.server_expired !== true ? 'text-white nbl' : 'disabled'}
                                                          data-for="cancel-server-1"
                                                          data-tip="Cancel Server"
                                                        >
                                                          <Icon.XLg className="icon" />
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </MDBCardBody>
                                                </MDBCard>
                                              </div>
                                })
                              }
                          </MDBRow>
                        </MDBCol>

                        <MDBCol sm="12" md="12" lg="12" xl="4">
                          <MDBCard className="mb-4 mb-md-0">
                            <MDBCardBody className="p-2">
                              <p className="mb-1 m-1">{t("Updates")}</p>
                              <div className="updates-window scroller">
                              {
                                !isLoading &&
                                news.length !== 0 &&
                                news.map((value, index) => {
                                  let className;
                                    switch(value.importance) {
                                      case 'high':
                                        className = 'border-red';
                                        break;
                                      case 'medium':
                                        className = 'border-yellow';
                                        break;
                                      case 'low':
                                      default:
                                        className = 'border-green';
                                        break;
                                    }

                                    return <div key={value._id}>
                                              <h5 className={`mb-0 text-white ${className}`}>{value.title}</h5>
                                              <small className="text-grey mb-2">{t('Created')}: {formatDate(value.posted)}</small>
                                              <hr className="mt-0 mb-0" />
                                              <p dangerouslySetInnerHTML={{
                                                  __html: sanitizeHtml(value.message, {
                                                    allowedSchemes: (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 
                                                    [ 'data', 'https', 'http' ] : [ 'data', 'https' ],
                                                    allowedTags: ['blockquote', 'cite', 'p', 'a', 'ul', 'ol', 'nl',
                                                                  'li', 'b', 'i', 's', 'strong', 'em', 'strike', 'code',
                                                                  'hr', 'br', 'div', 'table', 'thead','caption', 'tbody',
                                                                  'tr', 'th', 'td'],
                                                    allowedAttributes: {
                                                      a: [ 'href', 'target' ],
                                                    },
                                                    selfClosing: [ 'img', 'br', 'hr', 'link' ],
                                                  })
                                                }}>
                                              </p>
                                            </div>
                                })
                              }
                              </div>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>

                      </MDBRow>

                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </section>

            <Footer />
         </>
      )
};

export default Profile;