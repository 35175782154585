import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

const NavItemHeader = props => {
  var level = props.level;
  const { item } = props;
  const { title, menuclass, siteurl } = (item.product) ? item.product : item;
  const { wikichildren } = item;
  const location = useLocation();

  const [expanded, setExpand] = useState(
    location.pathname.includes(siteurl)
  );

  const onExpandChange = e => {
    e.preventDefault();
    setExpand(expanded => !expanded);
  };

  // Wiki header with children
  return (
    <>
      <NavLink
        to={(props.item.product ? props.item.product.siteurl : props.item.siteurl)}
        key={(item.product) ? item.product._id : item._id}
        className={`navItem navItemHeaderButton ${menuclass}`}
      >
        <span className={`navLabel ms-${level-(props.arrow ? 3 : 1)}`}>
          {props.arrow ? <Icon.Arrow90degRight className="text-white fs-7 ms-1" /> : ''} {title}
        </span>
        <Icon.CaretUpFill
          className={`navItemHeaderChevron fs-6 ${
            expanded && 'chevronExpanded'
          }`}
          onClick={onExpandChange}
        />
      </NavLink>

      {expanded && (
        // Looping through wiki children on extend
        <div className="m-0">
          {wikichildren.map((item, index) => {
            const key = `${item._id}-${menuclass}`;
            const { title, siteurl, wikichildren } = item;

            if (wikichildren && wikichildren.length !== 0) {
              level++;
              return (
                <div key={key}>
                  <NavItemHeader
                    arrow={true}
                    level={level+3}
                    item={{
                      ...item,
                      siteurl: siteurl,
                    }}
                  />
                </div>
              );
            }

            // Wiki parents without children
            return (
              <NavLink
                key={key}
                to={siteurl}
                className={`navItem`}
              >
                <span className={`navLabel ms-${level}`}><Icon.ArrowRight className="text-white fs-7 ms-1" /> {title}</span>
              </NavLink>
            );

          })}
        </div>
      )}
    </>
  );
};

export default NavItemHeader;