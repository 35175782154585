import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import * as Icon from 'react-bootstrap-icons';

const SpecsBanner = ({ className }) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  return (
    <>
      <div className="container-fluid">
        <div className={`row frontpage_banner justify-content-center d-flex ${className}`} ref={ref}>
          <ul className="list-group list-group-horizontal">
            <motion.li 
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: 0.10 }}
              className="list-group-item"
            >
              <Icon.ShieldLockFill className="icon" /> {t('DDoSProtection')}
            </motion.li>
            <motion.li 
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: 0.20 }}
              className="list-group-item"
            >
              <Icon.CpuFill className="icon" /> {t('CPUUsed')}
            </motion.li>
            <motion.li 
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: 0.30 }}
              className="list-group-item"
            >
              <Icon.CapslockFill className="icon" /> {t('OneClickSetup')}
            </motion.li>
            <motion.li 
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: 0.40 }}
              className="list-group-item"
            >
              <Icon.StopwatchFill className="icon" /> {t('InstantDelivery')}
            </motion.li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SpecsBanner;