import React, { useRef, useState, useEffect } from 'react';
import useAuth from '../_hooks/useAuth';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Select from 'react-select';
import SelectStyle from '../_helpers/SelectStyle';

import SiteLogo from '../_assets/images/logo.png';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as Icon from 'react-bootstrap-icons';

import { useTranslation } from 'react-i18next';

import GhostLazyBgImage from '../_helpers/GhostLazyBgImage';

import axios from '../_api/axios';

import Countries from '../_helpers/DKCountries';

import ErrorHandler from '../_helpers/ErrorHandler';

import { EMAIL_REGEX, PASSWORD_REGEX, toHHMMSS } from '../_helpers/Utils';

const REGISTER_URL = '/register';
const LOGIN_URL = '/auth';

/** LOGIN FUNCTION */
const Login = () => {
  const { setAuth, persist, setPersist, setIsLoggedIn } = useAuth();
  const [key, setKey] = useState('login');

  const { t } = useTranslation();

  const emailRef = useRef();
  const activatedRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [country, setCountry] = useState({});
  const [validCountry, setValidCountry] = useState(false);
  const [countryFocus, setCountryFocus] = useState(false);

  const [activated, setActivated] = useState(false);
  const params = useParams();

  const [hasAgreed, setHasAgreed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
      setErrMsg('');

      setValidEmail(EMAIL_REGEX.test(email));
      setValidPassword(PASSWORD_REGEX.test(password));
      setValidMatch(password === password2);

        if(params.activated) {
          setActivated(true);
        } else {
          setActivated(false);
        }

  }, [email, password, password2, params.activated])

  useEffect(() => {
    emailRef.current.focus();
  }, [])

  // const notready = () => {
  //   alert('This functionality is not ready yet');
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');

      try {
        const response = await axios.post(LOGIN_URL, 
          JSON.stringify({ email, password }),
            {
              headers: { 'Content-Type' : 'application/json' },
              withCredentials: true
            }
        );

        const accessToken = response?.data?.accessToken;
        const userid = response?.data?.userid;
        const roles = response?.data?.roles;
        const credit = response?.data?.credit ? parseInt(response?.data?.credit) : 0;

        setAuth({ email, password, roles, accessToken, userid, credit });
        setEmail('');
        setPassword('');

        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', true);

          navigate(from, { replace: true });

      } catch(err) {
        if(!err?.response) {
          setErrMsg(t("NoServerResponse"));
        } else if (err.response?.status === 400) {
          setErrMsg(t("MissingEmailAndPassword"));
        } else if (err.response?.status === 401) {
          setErrMsg(t("WrongEmailOrPassword"));
        } else if (err.response?.status === 403) {
          setErrMsg(t("LoginFailed"));
        } else if (err.response?.status === 429) {
          setErrMsg(t("TooManyFailedLogins", { expiresIn: toHHMMSS(err.response?.data?.expiresIn, true) }));
        } else if (err.response?.status === 423) {
          setErrMsg(t("AccountNeedsActivation"));
        } else {
          setErrMsg(t("LoginFailed"));
        }

      }
  }

  const handleRegister = async (e) => {
    e.preventDefault();
      setErrMsg('');
      
      // if button enabled with JS hack
      const v1 = PASSWORD_REGEX.test(password);
      const v2 = EMAIL_REGEX.test(email);

      if (!v1 || !v2 || password !== password2) {
          setErrMsg(t("InvalidEntry"));
          return;
      }

        try {
            await axios.post(REGISTER_URL,
              JSON.stringify({ email, password, country }),
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              }
            );

            setSuccess(true);
            //clear state and controlled inputs
            setPassword('');
            setPassword2('');
            setKey('login');
        } catch (err) {
            if (!err?.response) {
              setErrMsg(t("NoServerResponse"));
            } else if (err.response?.status === 400) {
              setErrMsg(t("MissingEmailAndPassword"));
            } else if (err.response?.status === 409 ||
                       err.response?.status === 422) {
              setErrMsg(t("EmailAlreadyInUse"));
            } else {
              setErrMsg(t("RegistrationFailed"))
            }

        }
  }

  const togglePersist = (e) => {
    setPersist(prev => !prev);
    localStorage.setItem('persist', e.target.checked);
  }

  const toggleAgreed = (e) => {
    setHasAgreed(e.target.checked);
  }

  const changeCountry = (e) => {
    setValidCountry(true);
    setCountryFocus(false);
    setCountry(e.value);
  }

  const onCountryFocus = () => {
    setCountryFocus(true);
  }

  const onCountryFocusOut = () => {
    setCountryFocus(false);
  }

  const goToRegister = (e) => {
    e.preventDefault();
      setKey('register');
  }

  const onTabChange = (k) => {
    // Set new tab
    setKey(k);

    // Update window url
    window.history.pushState({}, null, '/'+k);
  }

  useEffect(() => {
      localStorage.setItem('persist', persist);

        // Switch to register tab if url ends on /register
        if(location?.pathname === '/register'){
          setKey('register');
        }
  }, [persist, location])
  
  return (
    <>
      <GhostLazyBgImage />
      <div className="pt-5">
        <a href="/" className="d-flex justify-content-center">
          <img src={SiteLogo} alt="contacts" id="login_logo" />
        </a>
        <div className="container col-12 col-xs-3 col-md-5 col-lg-4 col-xl-3 mt-3 login-content">
          <div className="row">
            <ErrorHandler error={errMsg} />
            <p ref={activatedRef} className={activated && !errMsg ? "alert alert-success errmsg" : "offscreen"} aria-live="assertive">
              {t("TheAccountIsNowActivated")}!
            </p>
            <p className={success && !errMsg ? "alert alert-success" : "offscreen"} aria-live="assertive">
              <Icon.Check className="me-2 fs-2" />
              {t("YourAccountHasBeenCreated")}!
            </p>
            <Tabs
              defaultActiveKey="login"
              className="nav nav-pills nav-justified mb-3 d-flex logintabs"
              activeKey={key}
              onSelect={(k) => onTabChange(k)}
              transition={true}
            >
              <Tab className="nav-link" eventKey="login" title="Login">
                <form onSubmit={handleSubmit}>
                  <div className="form-outline mb-4 mt-5">
                    <input
                      type="text"
                      id="loginemail"
                      className="form-control"
                      ref={emailRef}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                    <label className="form-label" htmlFor="loginemail">
                      {t("Email")}
                    </label>
                  </div>

                  <div className="form-outline mb-4">
                    <input
                      type="password"
                      id="loginPassword"
                      autoComplete="off"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                    />
                    <label className="form-label" htmlFor="loginPassword">
                      {t("Password")}
                    </label>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-5 d-flex justify-content-center">
                      <div className="form-check mb-3 mb-md-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={togglePersist}
                          checked={persist}
                          value=""
                          id="loginCheck"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="loginCheck"
                        >
                          {t("RememberMe")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-7 d-flex justify-content-center">
                      <a href={`${process.env.REACT_APP_PANEL_URL}/auth/password`}>{t("ForgotPassword")} ?</a>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-success btn-block mb-4 w-100"
                  >
                    {t("SignIn")}
                  </button>

                  <div className="text-center">
                    <p>
                      {t("NotAMember")}? <a href="/" onClick={goToRegister}>{t("RegisterHere")}.</a>
                    </p>
                  </div>
                </form>
              </Tab>

              <Tab
                eventKey="register"
                title="Register"
                aria-labelledby="tab-register"
              >
                <form onSubmit={handleRegister}>
                  {/*<div className="text-center mb-1">
                    <p>{t("SignUpWidth")}</p>

                    <button
                      type="button"
                      className="btn btn-primary btn-floating m-2 steam"
                      onClick={notready}
                    >
                      <Icon.Steam />
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-floating m-2 discord"
                      onClick={notready}
                    >
                      <Icon.Discord />
                    </button>
                  </div>

                  <p className="text-center">{t("Or")}</p>*/}

                  <div className="form-outline mb-4 mt-5">
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        aria-invalid={validEmail ? "false" : "true"}
                        aria-describedby="emailnote"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                    />
                    <label className="form-label" htmlFor="registerEmail">
                      {t("Email")}
                      <Icon.Check className={validEmail ? "valid" : "hide"} />
                      <Icon.X className={validEmail || !email ? "hide" : "invalid"} />
                    </label>
                    <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                        <Icon.InfoCircleFill className="me-2" />
                        {t("EmailNote1")}<br />
                        {t("EmailNote2")}.
                    </p>
                  </div>

                  <div className="form-outline mb-4">
                    <input
                        type="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        autoComplete="off"
                        className="form-control"
                        required
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="passwordnote"
                        onFocus={() => setPasswordFocus(true)}
                        onBlur={() => setPasswordFocus(false)}
                    />
                    <label className="form-label" htmlFor="registerPassword">
                      {t("Password")}
                      <Icon.Check className={validPassword ? "valid" : "hide"} />
                      <Icon.X className={validPassword || !password ? "hide" : "invalid"} />
                    </label>
                    <p id="passwordnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                        <Icon.InfoCircleFill className="me-2" />
                        {t("PasswordNote1")}.<br />
                        {t("PasswordNote2")}.<br />
                        {t("PasswordNote3")}: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                    </p>
                  </div>

                  <div className="form-outline mb-4">
                    <input
                        type="password"
                        id="confirm_pwd"
                        onChange={(e) => setPassword2(e.target.value)}
                        value={password2}
                        autoComplete="off"
                        className="form-control"
                        required
                        aria-invalid={validMatch ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                    />
                    <label
                      className="form-label"
                      htmlFor="registerRepeatPassword"
                    >
                      {t("RepeatPassword")}
                      <Icon.Check className={validMatch && password2 ? "valid" : "hide"} />
                      <Icon.X className={validMatch || !password2 ? "hide" : "invalid"} />
                    </label>

                    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        <Icon.InfoCircleFill className="me-2" />
                        {t("RepeatPasswordNote")}.
                    </p>
                  </div>

                  <div className="form-outline mb-4">

                  <Select 
                    id="selectCountry"
                    options={Countries}
                    styles={SelectStyle}
                    onChange={changeCountry}
                    onFocus={onCountryFocus}
                    onBlur={onCountryFocusOut}
                    className="countryChooser" />

                    <label
                      className="form-label"
                      htmlFor="selectCountry"
                    >
                      {t("Country")}
                      <Icon.Check className={validCountry && country.value ? "valid" : "hide"} />
                      <Icon.X className={validCountry || !country.value ? "hide" : "invalid"} />
                    </label>

                    <p id="confirmnote" className={countryFocus && !validCountry ? "instructions" : "offscreen"}>
                        <Icon.InfoCircleFill className="me-2" />
                        {t("ChooseACountry")}
                    </p>
                  </div>

                  <div className="form-check d-flex justify-content-center mb-4">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      onChange={toggleAgreed}
                      value=""
                      id="registerCheck"
                      aria-describedby="registerCheckHelpText"
                    />

                    <label className="form-check-label" htmlFor="registerCheck">
                      {t("AgreeTermsStart")} <a href="/terms-of-service">{t("TermsOfService").toLowerCase()}.</a><br /> {t("AgreeTermsEnd")} <a href="/privacy-policy">{t("PrivacyPolicy").toLowerCase()}.</a>
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-success btn-block m-1 w-100"
                    disabled={!validEmail || !validPassword || !validMatch || !validCountry || !hasAgreed ? true : false}
                  >
                    {t("Register")}
                  </button>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
