const EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,30}$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const toHHMMSS = (milliseconds, fulltext = false) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours   = Math.floor(minutes / 60);
  const days    = Math.floor(hours / 24);

  const remainingHours   = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  const dayString    = days > 0 ? `${days}${!fulltext ? 'd' : ' dage'} ` : '';
  const hourString   = remainingHours > 0 ? `${remainingHours}${!fulltext ? 't' : ' timer'} ` : '';
  const minuteString = remainingMinutes > 0 ? `${remainingMinutes}${!fulltext ? 'm' : ' minutter'} ` : '';
  const secondString = remainingSeconds > 0 ? `${remainingSeconds}${!fulltext ? 's' : ' sekunder'} ` : '';

    return `${dayString}${hourString}${minuteString}${secondString}`.trim();
}

// Doing discount of the price
const doDiscount = (data) => {
  return Math.round(data.price - ((data.discount / 100) * data.price));
}

// Fetching the lowest package price
const minTotalByPrice = (data, name) => {
  if(!data) return 0;
    const totals = data.map(x => doDiscount(x));
      return Math.min(...totals);
}

// Calculate document height
const documentHeight = () => {
    return Math.min(
        window.innerHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight
    ) - 785;
}

// Items loaded pr page
const itemsPrPage = () => {
  const savedItemsPrPage = window.localStorage.getItem('itemsPrPage');
    return parseInt((savedItemsPrPage ? savedItemsPrPage : process.env.REACT_APP_PR_PAGE));
}

// Converts bytes into human readable size
const bytesToSize = (bytes, decimals = 2) => {
  if (!+bytes) return '0'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const nowDate = () => {
 let date = new Date();
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );

    return new Date(milliseconds);
}

const formatDate = (date, dateonly = false) => {
  const dt = new Date(date);
  const gday = dt.getUTCDate();
  const gmonth = dt.getUTCMonth()+1;
  const ghours = dt.getUTCHours();
  const gmins = dt.getUTCMinutes();

  const newdate = (gday <= 9 ? '0' + gday : gday) + '.' + (gmonth <= 9 ? '0' + gmonth : gmonth) + '.' + dt.getFullYear();
  const time = (ghours <= 9 ? '0' + ghours : ghours) + ':' + (gmins <= 9 ? '0' + gmins : gmins);
  const datetime = newdate + ' - ' + time;
  // const fdate = t("DateFormat", { date: datetime });

  // dd.mm.yyyy - hh:mm
  return datetime;
}

const strShorten = (str, length = 34) => {
  return str.length > length 
    ? `${str.substring(0, length)}…`
    : str
}

// Capitalize first letter
const ucFirst = str => {
  return str !== undefined ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

// Astrix the first 4 letters
const hidePhone = str => {
  return str !== undefined ? '****' + String(str).substr(4,8) : '';
}

const removeFadeOut = (el, speed = 2000) => {
  let seconds = speed / 1000;
    el.style.transition = "opacity " + seconds + "s ease";

    el.style.opacity = 0;
      setTimeout(function() {
          el.parentNode.removeChild(el);
      }, speed);
}

const randomItemKey = (length = 10000) => {
  return Math.floor((Math.random()*length) + 1);
}
 
export { 
  toHHMMSS,
  doDiscount,
  documentHeight,
  minTotalByPrice,
  itemsPrPage,
  bytesToSize,
  nowDate,
  formatDate,
  strShorten,
  ucFirst,
  hidePhone,
  removeFadeOut,
  randomItemKey,
  EMAIL_REGEX,
  PASSWORD_REGEX
};