import { useState, useEffect } from "react";

const randomBGs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
// Fetching all images
// const images = require.context('../_assets/images', true);

// Filtering only bgs from the map
// const imageList = images.keys().map(image => images(image)).filter(function(image) {
//     return image.startsWith('/static/media/bg');
// });

// Getting random index from the available array,
// dividing by 2 to strip out the thumbs
const randomBg = randomBGs[Math.floor((Math.random() * randomBGs.length))];
// console.log(randomBg);

/**
 * ---------------------------------------------------
 * Lazy Background Image Load
 * ---------------------------------------------------
 * Lazy loads out background images, so that the user
 * not have to wait for a laggy loading
 * ---------------------------------------------------
 */

// Loading the image with a promise
const loadImage = (src: string): Promise<string> =>
    new Promise((resolve, reject) => {
        const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = () => reject(new Error("Could not load image!"));
});

const GhostLazyBgImage = () => {
    const [loaded, setLoaded] = useState(false);
    const [src, setSrc] = useState(`${process.env.REACT_APP_BASE_URL}/_assets/bgs/bg${randomBg}_thumb.jpg`);

        useEffect(() => {
            const loadImageAsync = async (src) => {
                await loadImage(`${process.env.REACT_APP_BASE_URL}/_assets/bgs/bg${src}.jpg`)
                    .then((src) => {
                        setSrc(src);
                        setLoaded(true);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };

                if(!loaded) loadImageAsync(randomBg);
        }, [loaded]);

            return (
                <>
                    <div className="fullbg">
                        <img 
                            src={src}
                            loading="lazy"
                            alt="Background"
                            onLoad={() => setLoaded(true)}
                            className={`source ${loaded ? "loaded" : ""}`}
                        />
                    </div>
                </>
            );
}

export default GhostLazyBgImage;