import React, { useState, useRef, useEffect } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { useNavigate } from 'react-router-dom';

import useAuth from '../_hooks/useAuth';

import SiteLogo from '../_assets/images/logo.png';
// import * as Icon from 'react-bootstrap-icons';
import { Dna } from 'react-loader-spinner';

import { axiosPrivate } from '../_api/axios';

import { useTranslation } from 'react-i18next';

import GhostLazyBgImage from '../_helpers/GhostLazyBgImage';

const CHARGE_REGEX = /[0-9]{0,30}$/;
const CHECKOUT_URL = '/payment/checkout';
const PAYMENT_URL = '/payment';

/** CHARGE FUNCTION */
const Charge = () => {
  const { auth } = useAuth();

  const amountRef = useRef();
  const errRef = useRef();

  const { t } = useTranslation();
  const langCurrency = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).currency : 'kr'

  const [validCharge, setValidCharge] = useState(false);
  const [chargeAmount, setChargeAmount] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const [errMsg, setErrMsg] = useState('');

  const navigate = useNavigate();

  const [currentCredit, setCurrentCredit] = useState(auth ? auth?.credit : 0);

  const style = {
    layout: "vertical",
    color: "black"
  };

  const initialOptions = {
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_API_KEY,
      currency: "DKK",
      intent: "capture"
  };

  const changeAmount = (e) => {
    setChargeAmount(e.target.value ? parseInt(e.target.value) : 0);
  }

  const onPayPalLoaded = () => {
    setIsLoading(false);
  }

  useEffect(() => {
    setErrMsg('');
    setValidCharge(CHARGE_REGEX.test(chargeAmount));
  }, [chargeAmount, setValidCharge])

  return (
    <>
      <GhostLazyBgImage />
      <div className="pt-5">
        <a href="/" className="d-flex justify-content-center">
          <img src={SiteLogo} alt="contacts" id="login_logo" />
        </a>
        <div className="container col-xs-1 col-md-6 col-lg-4 col-xl-3 mt-5 login-content paypal">
          <div className="row">

              <form>
                <div className="text-center mb-1">
                  <h5 className="m-1">{t("ChargeCreditHereMsg")}</h5>
                  <p className="text-success">{t("Credit")}: {t("CurrencyFormat", { currency: currentCredit })}</p>
                </div>

                <div className="form-outline mb-0">
                  <p ref={errRef} className={errMsg ? "alert alert-danger errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                  <input
                    type="number"
                    id="chargeAmount"
                    className="form-control text-center"
                    aria-invalid={validCharge ? "false" : "true"}
                    ref={amountRef}
                    autoComplete="off"
                    onChange={(e) => changeAmount(e)}
                    value={chargeAmount}
                    required
                  />
                  <label className="form-label mb-4" htmlFor="chargeAmount">
                    {t("ChargeAmount")} <small>( {langCurrency} )</small>
                  </label>
                    { isLoading ? 
                      <>
                        <div className="payment-loader">
                          <Dna
                            width="50"
                            height="50"
                            wrapperClass="dna-wrapper"
                          />
                          <p>{t("LoadingPayment")}</p>
                        </div>
                      </> : '' }

                        <PayPalButton
                          style={style}
                          disabled={false}
                          onButtonReady={onPayPalLoaded}
                          options={initialOptions}
                          forceReRender={[chargeAmount, style]}
                          onClick={(data, actions) => {

                            if(!CHARGE_REGEX.test(chargeAmount) || chargeAmount === 0) {
                              setErrMsg(t("NotEnoughToProceed")+'!');
                              return false;
                            }

                          }}

                          createOrder={async (data, actions) => { 
                            // Creating account charge order
                            const createChargeOrder = async (url) => {
                              try {
                                const response = await axiosPrivate.post(PAYMENT_URL,
                                    JSON.stringify({ chargeAmount }),
                                    {
                                      headers: { 
                                        'Content-type': 'application/json',
                                        'Authorization': `Bearer ${auth?.accessToken}`
                                      },
                                      withCredentials: true
                                    }
                                );

                                if(response) {
                                  return response.data.orderID;
                                }

                              } catch(err) {
                                console.error(err.message);
                              } finally {
                                
                              }
                            }

                              return await createChargeOrder(`${process.env.REACT_APP_BACKEND_URL}${PAYMENT_URL}`);

                          }}

                          onApprove={async (data, actions) => {

                            const orderID = data.orderID;

                            // Validating account charge order
                            const onCheckoutApprove = async (url) => {
                              try {
                                const response = await axiosPrivate.post(url,
                                    JSON.stringify({ orderID }),
                                    {
                                      headers: { 
                                        'Content-type': 'application/json',
                                        'Authorization': `Bearer ${auth?.accessToken}`,
                                      },
                                      withCredentials: true
                                    }
                                );

                                if(response) {
                                  setCurrentCredit(currentCredit+chargeAmount);

                                    setTimeout(() => {
                                      navigate('/profile', { replace: false });
                                    }, 500);

                                      return orderID;
                                }

                              } catch(err) {
                                console.error(err.message);
                              } finally {
                                
                              }
                            }

                              await onCheckoutApprove(`${process.env.REACT_APP_BACKEND_URL}${CHECKOUT_URL}`);
                          }}

                          onCancel={() => {
                            navigate('/charge', { replace: true });
                          }}

                          onError={(err) => {
                            console.error(err);
                            setErrMsg(t("CouldNotCheckOutFromPayPal"));
                          }}
                        />

                </div>

              </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Charge;
