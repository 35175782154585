import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
 
import { TRANSLATIONS_DK } from './dk/translations';
import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_EU } from './eu/translations';
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
    lng: 'dk',
    fallbackLng: 'dk',
    resources: {
      dk: {
        translation: TRANSLATIONS_DK
      },
      en: {
        translation: TRANSLATIONS_EN
      },
      eu: {
        translation: TRANSLATIONS_EU
      }
    }
 });

 export default i18n;