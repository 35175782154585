import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion'
import * as Icon from 'react-bootstrap-icons';
import { doDiscount } from './Utils';


/**
 * ---------------------------------------------------
 * Stock color
 * ---------------------------------------------------
 * Shows different colors when reaching certain levels
 * of stock amount.
 * ---------------------------------------------------
 */

const StockColor = (stock) => {
  if(stock <= parseInt(process.env.REACT_APP_MIN_STOCK)) {
    return 'red';
  } else if (stock <= parseInt(process.env.REACT_APP_MEDIUM_STOCK)) {
    return 'yellow';
  } else {
    return 'green';
  }
}


/**
 * ---------------------------------------------------
 * Product cards for every pages
 * ---------------------------------------------------
 * These cards are used over the entire site, to make
 * it more dynamic, so that we only have to change 
 * them in one place.
 * They can show discount, or hide buy btn and tags.
 * Every product have a template, they can load their
 * data into. 
 * This means that they can change the way they look
 * from product to product.
 * ---------------------------------------------------
 */

const ProductWrapper = (value) => {
  const { t }    = useTranslation();
  const data     = value.value ? value.value : value;
  const video    = value.video ? value.video  : 'minecraft-bumper.mp4';
  const delay    = value.delay;

  let template   = '',
      stock      = parseInt(data.stock),
      discount   = doDiscount(data),
      badgeClass = StockColor(stock);

  switch(String(data.template)) {
    default:
      break;

    // ---------------------
    // Minecraft
    // ---------------------
    case '0':
      template = 
        <>
        <div className={`pw card-wrapper ${value.className !== undefined ? value.className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${value.video ? 'video' : ''} ${value.className ? value.className : ''}`}>
            { value.video ? (
              <div id="video-overlay">
                <video id="video-preview" muted={true} loop={true} autoPlay={true}>
                  <source src={`_assets/videos/${video}`} type="video/mp4" />
                </video>
              </div>
              ) : ('')
            }
            { !value.noPrice && data.discount !== 0 ? (
              <div className="ribbon-wrapper">
                <div className="ribbon">{t("DiscountMsg")} {data.discount}%</div>
              </div>
              ) : (
                ''
            )}
           <h3 className={`card-header ${data.iconclass}${value.video ? ' video-header' : ''}`}>
             {t(data.titlelang)} 
             { !value.noBadge ? (
                <span className={`badge badge-success ${badgeClass}`}>{ data.stock >= 99 ? '99+' : data.stock !== 0 ? data.stock : "!" }</span>
              ) : ('')}
           </h3>
           { !value.noPrice ? (
                data.discount !== 0 ? (
                  <h2 className="price">{t('CurrencyFormat', { currency: discount })}<small> / {t('FirstMonth')}</small></h2>                  
                ) : (
                  <h2 className="price">{t('CurrencyFormat', { currency: data.price })}<small> / {t('AMonth')}</small></h2>
                )
              ) : (
                ''
            )}
           <p className={`m-1 ${value.video ? 'ms-3' : ''}`}>
             {t(data.descriptionlang)}
           </p>
           <ul className={`m-2 ${value.video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: data.slots })}</p>
            </li>
            <li>
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: data.ram })}</p>
            </li>
            <li>
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: data.cpu, clockspeed: data.cputype })}</p>
            </li>
            <li>
              <Icon.DeviceSsdFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBDisk', { disk: data.disk })}</p>
            </li>
            <li>
              <Icon.MapFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('LiveMapAvailable')}, <a href="https://bluecolored.de/bluemap/#overworld:-936:54:198:59:-0.17:0.45:0:0:perspective" target="_new">{t('DemoHere')}</a></p>
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: data.network })}</p>
            </li>*/}
            <li>
              <Icon.ShieldLockFill className="me-1 fs-5"/>
              <p className="fs-6">{t('DDoSProtection')}</p>
            </li>
          </ul>
            { !value.noBtn ? <a href={data.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a> : ''}
          </div>
        </div>
        </>
        break;


    // ---------------------
    // CS:GO
    // ---------------------
    case '1':
      template =
        <>
        <div className={`pw card-wrapper ${value.className !== undefined ? value.className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${value.video ? 'video' : ''} ${value.className ? value.className : ''}`}>
            { value.video ? (
              <div id="video-overlay">
                <video id="video-preview" muted={true} loop={true} autoPlay={true}>
                  <source src={`_assets/videos/${video}`} type="video/mp4" />
                </video>
              </div>
              ) : ('')
            }
            { !value.noPrice && data.discount !== 0 ? (
              <div className="ribbon-wrapper">
                <div className="ribbon">{t("DiscountMsg")} {data.discount}%</div>
              </div>
              ) : (
                ''
            )}
           <h3 className={`card-header ${data.iconclass} ${value.video ? 'video-header' : ''}`}>
             {t(data.titlelang)} 
             { !value.noBadge ? (
                <span className={`badge badge-success ${badgeClass}`}>{ data.stock >= 99 ? '99+' : data.stock !== 0 ? data.stock : "!" }</span>
              ) : ('')}
           </h3>
           { !value.noPrice ? (
                data.discount !== 0 ? (
                  <h2 className="price">{t('CurrencyFormat', { currency: discount })}<small> / {t('FirstMonth')}</small></h2>                  
                ) : (
                  <h2 className="price">{t('CurrencyFormat', { currency: data.price })}<small> / {t('AMonth')}</small></h2>
                )
              ) : (
                ''
            )}
           <p className={`m-1 ${value.video ? 'ms-3' : ''}`}>
             {t(data.descriptionlang)}
           </p>
           <ul className={`m-2 ${value.video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: data.slots })}</p>
            </li>
            <li>
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: data.ram })}</p>
            </li>
            <li>
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: data.cpu, clockspeed: data.cputype })}</p>
            </li>
            <li>
              <Icon.Speedometer className="me-1 fs-5" /> 
              <p className="fs-6">{t('Ticks', { ticks: data.disk })}</p>
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: data.network })}</p>
            </li>*/}
            <li>
              <Icon.ShieldLockFill className="me-1 fs-5"/>
              <p className="fs-6">{t('DDoSProtection')}</p>
            </li>
          </ul>
            { !value.noBtn ? <a href={data.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a> : ''}
          </div>
        </div>
        </>
        break;


    // ---------------------
    // ARK Survival
    // ---------------------
    case '2':
      template =
        <>
        <div className={`pw card-wrapper ${value.className !== undefined ? value.className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${value.video ? 'video' : ''} ${value.className ? value.className : ''}`}>
            { value.video ? (
              <div id="video-overlay">
                <video id="video-preview" muted={true} loop={true} autoPlay={true}>
                  <source src={`_assets/videos/${video}`} type="video/mp4" />
                </video>
              </div>
              ) : ('')
            }
            { !value.noPrice && data.discount !== 0 ? (
                <div className="ribbon-wrapper">
                  <div className="ribbon">{t("DiscountMsg")} {data.discount}%</div>
                </div>
              ) : (
                ''
            )}
           <h3 className={`card-header ${data.iconclass} ${value.video ? 'video-header' : ''}`}>
             {t(data.titlelang)} 
             { !value.noBadge ? (
                <span className={`badge badge-success ${badgeClass}`}>{ data.stock >= 99 ? '99+' : data.stock !== 0 ? data.stock : "!" }</span>
              ) : ('')}
           </h3>
           { !value.noPrice ? (
                data.discount !== 0 ? (
                  <h2 className="price">{t('CurrencyFormat', { currency: discount })}<small> / {t('FirstMonth')}</small></h2>                  
                ) : (
                  <h2 className="price">{t('CurrencyFormat', { currency: data.price })}<small> / {t('AMonth')}</small></h2>
                )
              ) : (
                ''
            )}
           <p className={`m-1 ${value.video ? 'ms-3' : ''}`}>
             {t(data.descriptionlang)}
           </p>
           <ul className={`m-2 ${value.video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: data.slots })}</p>
            </li>
            <li>
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: data.ram })}</p>
            </li>
            <li>
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: data.cpu, clockspeed: data.cputype })}</p>
            </li>
            <li>
              <Icon.DeviceSsdFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBDisk', { disk: data.disk })}</p>
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: data.network })}</p>
            </li>*/}
            <li>
              <Icon.ShieldLockFill className="me-1 fs-5"/>
              <p className="fs-6">{t('DDoSProtection')}</p>
            </li>
          </ul>
            { !value.noBtn ? <a href={data.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a> : ''}
          </div>
        </div>
        </>
        break;


    // ---------------------
    // V Rising
    // ---------------------
    case '3':
      template =
        <>
        <div className={`pw card-wrapper ${value.className !== undefined ? value.className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${value.video ? 'video' : ''} ${value.className ? value.className : ''}`}>
            { value.video ? (
              <div id="video-overlay">
                <video id="video-preview" muted={true} loop={true} autoPlay={true}>
                  <source src={`_assets/videos/${video}`} type="video/mp4" />
                </video>
              </div>
              ) : ('')
            }
            { !value.noPrice && data.discount !== 0 ? (
                <div className="ribbon-wrapper">
                  <div className="ribbon">{t("DiscountMsg")} {data.discount}%</div>
                </div>
              ) : (
                ''
            )}
           <h3 className={`card-header ${data.iconclass} ${value.video ? 'video-header' : ''}`}>
             {t(data.titlelang)} 
             { !value.noBadge ? (
                <span className={`badge badge-success ${badgeClass}`}>{ data.stock >= 99 ? '99+' : data.stock !== 0 ? data.stock : "!" }</span>
              ) : ('')}
           </h3>
           { !value.noPrice ? (
                data.discount !== 0 ? (
                  <h2 className="price">{t('CurrencyFormat', { currency: discount })}<small> / {t('FirstMonth')}</small></h2>                  
                ) : (
                  <h2 className="price">{t('CurrencyFormat', { currency: data.price })}<small> / {t('AMonth')}</small></h2>
                )
              ) : (
                ''
            )}
           <p className={`m-1 ${value.video ? 'ms-3' : ''}`}>
             {t(data.descriptionlang)}
           </p>
           <ul className={`m-2 ${value.video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: data.slots })}</p>
            </li>
            <li>
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: data.ram })}</p>
            </li>
            <li>
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: data.cpu, clockspeed: data.cputype })}</p>
            </li>
            <li>
              <Icon.DeviceSsdFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBDisk', { disk: data.disk })}</p>
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: data.network })}</p>
            </li>*/}
            <li>
              <Icon.ShieldLockFill className="me-1 fs-5"/>
              <p className="fs-6">{t('DDoSProtection')}</p>
            </li>
          </ul>
            { !value.noBtn ? <a href={data.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a> : ''}
          </div>
        </div>
        </>
        break;
  }

    return (
      !value.noAnim ? (
          <>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
              transition={{ duration: 0.5, delay: delay }}
            > 
              {template}
            </motion.div>
          </>
        ) : (
          <>
            {template}
          </>
        )
    );
}

export { ProductWrapper, StockColor };