import Header from '../_components/Header';
import Footer from '../_components/Footer';
// import io from 'socket.io-client';
// import * as Icon from 'react-bootstrap-icons';
import IframeResizer from 'iframe-resizer-react'
import { documentHeight } from '../_helpers/Utils';

const Latency = () => {
  return (
    <>
      <Header />
      <div id="header_static" className="default-bg container-fluid product mini"></div>

        <div className="htframe" style={{ minHeight: `${documentHeight()}px` }}>
          <IframeResizer 
            src="https://wl.hetrixtools.com/r/b012c9edb6550b3bdb619700dc102d30/"
            className="htframe-wrapper"
            heightCalculationMethod="lowestElement"
            title="Ghost Status Page"
            scrolling="no"
            sandbox="allow-scripts allow-same-origin allow-popups"
          />
        </div>
      <Footer />
    </>
  );
};

export default Latency;