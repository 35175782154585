import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './_context/AuthProvider'
import './index.css';
import App from './App';

if(['localhost', '127.0.0.1', ''].includes(window.location.hostname)) {
  console.log('In Development Mode!');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// <React.StrictMode>
root.render(
  ['localhost', '127.0.0.1', ''].includes(window.location.hostname) ? (
      <AuthProvider>
        <App />
      </AuthProvider>
  ) : (
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
