import React from 'react';
import { NavLink } from 'react-router-dom';
import NavItemHeader from './NavItemHeader.jsx';

const NavItem = props => {
  const { title, menuclass, siteurl, _id } = props.item.product;
  const { wikichildren } = props.item;

  if (wikichildren) {
    return <NavItemHeader level={1} arrow={true} item={props.item} />;
  }

  return (
    <NavLink
      to={siteurl}
      className={`navItem ${menuclass}`}
      key={_id}
    >
      <span className="navLabel">{title}</span>
    </NavLink>
  );
};

export default NavItem;