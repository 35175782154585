import { useTranslation } from 'react-i18next';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

import Header from '../_components/Header';
import Footer from '../_components/Footer';

import UserMenu from '../_components/UserMenu';

const NoPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product mini">
        </div>
        <section>
          <MDBContainer className="py-4">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">
                  <MDBBreadcrumbItem>
                    <a href='/'>{t("Home")}</a>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>{t("Unothorized")}</MDBBreadcrumbItem>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3">
                <UserMenu />
              </MDBCol>

              <MDBCol lg="9">
                <MDBCard className="mb-4">
                  <MDBCardBody>

                    <MDBRow>
                      <MDBCol sm="12">
                        <MDBCardText>{t("Unothorized")}</MDBCardText>
                      </MDBCol>
                    </MDBRow>

                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

      <Footer />
    </>
  )
};

export default NoPage;