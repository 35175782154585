import { useEffect, useState } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBCardText,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import axios from '../_api/axios';

import Header from '../_components/Header';
import Footer from '../_components/Footer';
import WikiMenu from '../_components/WikiMenu';
import { ucFirst, documentHeight } from '../_helpers/Utils';
import * as sanitizeHtml from 'sanitize-html-react';

const Wiki = () => {
  const loadingContext = useLoadingContext();
  const { t } = useTranslation();
  const params = useParams();

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;
      const getWiki = async (url) => {
        setIsLoading(true);
          try {
            const response = await axios.get(url,
               JSON.stringify({ siteurl: params }),
                {
                  headers: { 'Content-Type' : 'application/json' },
                  withCredentials: true
                }
            );

              if (isMounted) {
                  setData(response.data);
                  setFetchError(null);
              }


              loadingContext.done();
          } catch(err) {
              if (isMounted) {
                  setFetchError(err.message);
                  setData([]);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

        if(params.serverName !== undefined) {
          let wikiurl = params.serverName;

          if(params.wikiTopic !== undefined) {
            wikiurl = params.serverName + '/' + params.wikiTopic
          }

          if(params.wikiPost !== undefined) {
            wikiurl = params.serverName + '/' + params.wikiTopic + '/' + params.wikiPost
          }

          getWiki(`${process.env.REACT_APP_BACKEND_URL}/wiki/${wikiurl}`);
        } else {
          // No wikipedia selected
        }

      const cleanUp = () => {
          isMounted = false;
      }

      return cleanUp;
  }, [fetchError, params, loadingContext]);

  return (
    <>
      <Header />
        <div id="header_static" className="wiki-bg container-fluid product">
          <div className="product_top d-none d-lg-block">
            <h1>{t("Wiki").toUpperCase()}</h1>
          </div>
        </div>
        <section>
          <MDBContainer className="py-4">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">
                  <MDBBreadcrumbItem>
                    <a href='/'>{t("Home")}</a>
                  </MDBBreadcrumbItem>

                  {!isLoading && !params.serverName && !params.wikiTopic && !params.wikiPost && (
                    <MDBBreadcrumbItem active>
                      <a href='/wiki'>{t("Wiki")}</a>
                    </MDBBreadcrumbItem>
                  )}

                  {!isLoading && params.serverName && !params.wikiTopic && !params.wikiPost && (
                    <>
                      <MDBBreadcrumbItem>
                        <a href='/wiki'>{t("Wiki")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>
                        {ucFirst(params.serverName)}
                      </MDBBreadcrumbItem>
                    </>
                  )}

                  {!isLoading && params.serverName && params.wikiTopic && !params.wikiPost && (
                    <>
                      <MDBBreadcrumbItem>
                        <a href='/wiki'>{t("Wiki")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href={`/wiki/${params.serverName}`}>{ucFirst(params.serverName)}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>
                        {ucFirst(data.parent)}
                      </MDBBreadcrumbItem>
                    </>
                  )}

                  {!isLoading && params.serverName && params.wikiTopic && params.wikiPost && (
                    <>
                      <MDBBreadcrumbItem>
                        <a href='/wiki'>{t("Wiki")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href={`/wiki/${params.serverName}`}>{ucFirst(params.serverName)}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href={`/wiki/${params.serverName}/${params.wikiTopic}`}>{ucFirst(data.parent)}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>
                        {ucFirst(data.title)}
                      </MDBBreadcrumbItem>
                    </>
                  )}
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3">
                <WikiMenu />
              </MDBCol>

              <MDBCol lg="9" style={{ minHeight: `${documentHeight()}px` }}>
                <MDBCard className="mb-4">
                  <MDBCardBody>

                    <MDBRow>
                      <MDBCol sm="12">
                        <MDBCardHeader className="p-0">
                          {!isLoading && !params.serverName && !params.wikiTopic && !params.wikiPost && (
                            <h4>{t('SelectWikipedia')}</h4>
                          )}
                          {!isLoading && params.serverName && (
                            <h4>{data.title}</h4>
                          )}
                        </MDBCardHeader>

                        {!isLoading && !params.serverName && !params.wikiTopic && !params.wikiPost && (
                          <MDBCardText>
                            {t('NoWikipediaSelected')}.
                          </MDBCardText>
                        )}
                        {!isLoading && params.serverName && (
                          <MDBCardText dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(data.content, {
                              allowedSchemes: (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 
                              [ 'data', 'https', 'http' ] : [ 'data', 'https' ],
                              allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'cite', 'p',
                                            'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 's', 'strong', 'em',
                                            'strike', 'code', 'hr', 'br', 'div', 'table', 'thead',
                                            'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img', 'figure',
                                            'figcaption'],
                              allowedAttributes: {
                                a: [ 'href', 'target' ],
                                img: [ 'src', 'alt', 'class', 'title' ]
                              },
                              selfClosing: [ 'img', 'br', 'hr', 'link' ],
                            })
                          }}>
                          </MDBCardText>
                        )}
                      </MDBCol>
                    </MDBRow>

                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

      <Footer />
    </>
  )
};

export default Wiki;