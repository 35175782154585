/**
 * ---------------------------------------------------
 * Access roles list
 * ---------------------------------------------------
 * These are the roles, that allow users to be in
 * certain places on the platform, or who to deny!
 * ---------------------------------------------------
 */
const ROLES = {
  'User': 2457,
  'Premium': 3875,
  'Moderator': 4295,
  'Admin': 8645,
}

export default ROLES;