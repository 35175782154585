import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../_hooks/useRefreshToken';
import LoadingScreen from '../_components/LoadingScreen';
import useAuth from '../_hooks/useAuth';


/**
 * ---------------------------------------------------
 * Handles routes with authentication overlay
 * ---------------------------------------------------
 * This ensures a secure overlay to the different
 * routes, acts as a middleware and is used to avoid
 * unwanted activity in different parts of the platform.
 * If the refresh token is valid, it refreshes it
 * and stores it in the DB with the old refresh tokens
 * under the found user.
 * ---------------------------------------------------
 * @params isLoading, refresh, auth
 * @output security/access/routes
 * ---------------------------------------------------
 */

const PersistLogin = () => {
    const { auth, persist } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();

        useEffect(() => {
            let isMounted = true;

                // console.log(`Persist: ${persist}`);
                // console.log(auth);

                // Checks validation and availability of the refreshing token
                const verifyRefreshToken = async () => {
                    try {
                        await refresh();
                    }
                    catch (err) {
                        console.error(err);
                        localStorage.removeItem('isLoggedIn');
                    }
                    finally {
                        // isMounted is added to avoid memory leak
                        isMounted && setIsLoading(false);
                    }
                }

                // Avoids unwanted call to verifyRefreshToken
                // !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
                localStorage.getItem('isLoggedIn') && !auth.accessToken ? verifyRefreshToken() : setIsLoading(false);

                    // Unmounts the component when used, to avoid memory leak
                    return () => isMounted = false;

        }, [auth?.accessToken, persist, refresh])

    // Preloader template
    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <LoadingScreen />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin