import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import {
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import axios from '../_api/axios';
import NavItem from './menu/NavItem.jsx';

const WikiMenu = () => {
  // const { t } = useTranslation();
  const params = useParams();

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let isMounted = true;
      const getWikis = async (url) => {
        setIsLoading(true);
          try {
            const response = await axios.get(url, {
               headers: {
                  'Content-type': 'application/json',
               }
            });

              if (isMounted) {
                  setProducts(response.data);
                  setFetchError(null);
              }

          } catch(err) {
              if (isMounted) {
                  setFetchError(err.message);
                  setProducts([]);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      getWikis(`${process.env.REACT_APP_BACKEND_URL}/wiki`);


      const cleanUp = () => {
          isMounted = false;
      }

      return cleanUp;
  }, [fetchError, params]);

  return (
    <MDBCard className="mb-4 mb-lg-0">
      <MDBCardBody className="p-0">
        <MDBListGroup>
          {isLoading && (<p className="m-2">Loading Wikis...</p>)}
          {!isLoading &&
           products &&
           products.map((item, index) => {
            
            return (
              <MDBListGroupItem key={`${item.product._id}-gi`}>
                <NavItem key={item.product._id} item={item} />
              </MDBListGroupItem>
            )
          })}
        </MDBListGroup>
      </MDBCardBody>
    </MDBCard>
  );
};
export default WikiMenu;