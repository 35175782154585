import { useTranslation } from 'react-i18next';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import { documentHeight } from '../_helpers/Utils';

const NoPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product mini" style={{ minHeight: `${documentHeight()}px` }}>
          <div className="product_top">
            <h1>404</h1>
            <h2>{t("PageNotFound")}</h2>
              <p>{t("PageNotFoundMsg")}.</p>
          </div>
        </div>
        <div style={{ minHeight: `${documentHeight()-100}px` }}>
        </div>
      <Footer />
    </>
  )
};

export default NoPage;