import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoadingContext } from 'react-router-loading';
import * as Icon from 'react-bootstrap-icons';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import axios from '../_api/axios';
import { useTranslation } from 'react-i18next';
import SpecsBanner from '../_components/banners/SpecsBanner';
import Pagination from '../_components/Pagination';
import PrPageSelect from '../_helpers/PrPageSelect';
import { ProductWrapper } from '../_helpers/ProductWrapper';
import { itemsPrPage } from '../_helpers/Utils';
import ErrorHandler from '../_helpers/ErrorHandler';

import {
  MDBCol,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBDropdown,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';

const GameServers = () => {
  const params = useParams();
  const { t } = useTranslation();
  const loadingContext = useLoadingContext();
  const [showMenu, setShowMenu] = useState('hide');
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [allPackages, setAllPackages] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [currentPackages, setCurrentPackages] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);

  // Toggles menu on mobiles when hover is deactivated
  const openMenu = async (e) => {
    e.preventDefault();
      setShowMenu(showMenu === 'hide' ? 'show' : 'hide');
  }

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
      const newOffset = (event.selected * itemsPrPage()) % allPackages.length;
        await setItemOffset(newOffset);
          await setCurrentPackages(allPackages.slice(newOffset, newOffset + itemsPrPage()));
            if(itemsPrPage() !== 4 && itemsPrPage() !== 8) window.scrollTo(0, 0);
            
  }

  // Choosing product by filter
  const loadBySiteUrl = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let foundOne = false,
        tmpPackages = [];

      allProducts.map((productValue, index) => {
        if(/[^/]*$/.exec(e.target.href)[0] === /[^/]*$/.exec(productValue.siteurl)[0]) {
          setCurrentProduct(productValue);

            productValue.packages.map((packValue, packIndex) => {
              return tmpPackages.push(packValue);
            });

          setAllPackages(tmpPackages);
          setCurrentPackages(tmpPackages.slice(itemOffset, (itemOffset + itemsPrPage())));

          // Creating browser history
          window.history.pushState(null, productValue.title, e.target.href);

          params.serverName = /[^/]*$/.exec(productValue.siteurl)[0];

          // We found a match ?
          foundOne = true;
        }

          return true;
      });

      // If we not found a match
      // return all servers
      if(!foundOne) {

        // Clears $_GET param
        params.serverName = '';

        // If no choosen product found
        // fetch all packages again
        setCurrentProduct(null);
          allProducts.map((productValue, index) => {
            productValue.packages.map((packValue, packIndex) => {
              return tmpPackages.push(packValue);
            });

              return true;
          });

        setAllPackages(tmpPackages);

        // Creating browser history
        window.history.pushState(null, 'Game Servers', e.target.href);
      }

      setCurrentPackages(tmpPackages.slice(0, itemsPrPage()));
      setItemOffset(0);
      setIsLoading(false);
  }

  // Getting all products
  useEffect(() => {
    let isMounted = true;
      const getProducts = async (url) => {
        setIsLoading(true);
          try {
            const response = await axios.post(url, {
               headers: {
                  'Content-type': 'application/json',
               }
            });

              if (isMounted) {
                  // Looping through all products
                  response.data.products.map((productValue, index) => {

                      // If we already have selected a product
                      if(params.serverName && params.serverName !== '') {

                        // If the product is equal to the choosen one
                        if(/[^/]*$/.exec(params.serverName)[0] === /[^/]*$/.exec(productValue.siteurl)[0]) {

                          // Fetching all selected packages
                          productValue.packages.map((packValue, packIndex) => {
                              return allPackages.push(packValue);
                          });
                        }
                      } else {
                        // Fetching all packages if none is selected
                        productValue.packages.map((packValue, packIndex) => {
                            return allPackages.push(packValue);
                        });
                      }

                        return setAllProducts(response.data.products);
                  })

                  const endOffset = itemOffset + itemsPrPage();

                  setCurrentPackages(allPackages.slice(itemOffset, endOffset));

                  setFetchError(null);
                  setLoadedOnce(true);
              }

              loadingContext.done();
          } catch(err) {
              if (isMounted) {
                  setFetchError(err.message);
                  setAllPackages([]);
                  setAllProducts([]);
                  setCurrentPackages([]);
                  setLoadedOnce(false);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      if(!loadedOnce) {
        getProducts(`${process.env.REACT_APP_BACKEND_URL}/products`);
      }

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, [fetchError, loadingContext, allPackages, allProducts, itemOffset, params, loadedOnce]);


  // Load choosen game type
  useEffect(() => {
    if(params.serverName && !isLoading){

      allProducts.map((value, index) => {
        if(/[^/]*$/.exec(params.serverName)[0] === /[^/]*$/.exec(value.siteurl)[0]){
          setCurrentProduct(value);
        }

          return true;
      });
    }

  }, [params, allProducts, isLoading]);
  
  return (
    <>
      <Header />
        <div id="header_static" className={`${currentProduct !== null ? currentProduct.headerclass : 'wiki-bg'} container-fluid product top-bg`}>
          <div className="product_top d-none d-lg-block">
            <h1>{currentProduct !== null ? currentProduct.title.toUpperCase() : ''} {t("GameServers").toUpperCase()}</h1>
            { currentProduct !== null ? <p>{ t(currentProduct.Description) }</p>: '' }
          </div>

          <div className="container pull-down">

            <div className="row">
              <div className="products">
                <MDBRow>
                  <MDBCol>
                    <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">

                      <MDBCol className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-10">
                        <MDBBreadcrumbItem>
                          <a href='/'>{t("Home")}</a>
                        </MDBBreadcrumbItem>
                        { 
                          currentProduct === null ? (
                            <>
                              <MDBBreadcrumbItem active>{t("GameServers")}</MDBBreadcrumbItem>
                            </>
                          ) : (
                            <>
                              <MDBBreadcrumbItem>
                                <a href="/overview">{t("GameServers")}</a>
                              </MDBBreadcrumbItem>
                              <MDBBreadcrumbItem active>{currentProduct.title}</MDBBreadcrumbItem>
                            </>
                          )
                        }
                      </MDBCol>
                      <MDBCol className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-1">
                        <PrPageSelect className="me-4" handlePageClick={handlePageClick} />
                      </MDBCol>
                      <MDBCol className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-1">

                        <MDBDropdown className="dropdown-wrapper pull-right">
                          <a href="/" onClick={openMenu} tag='a' aria-current='page' className='game-servers'>
                             {t("ChooseAPackage")} <Icon.CaretDownFill className="fs-7" />
                          </a>
                          <ul className={`dropdown-menu animation game-servers fade-up ${showMenu}`}>
                            <MDBDropdownItem link key="0" onClick={loadBySiteUrl} href="/game-servers" className="default">{t("AllServers")}</MDBDropdownItem>
                            { !isLoading && 
                              allProducts.length !== 0 && 
                              allProducts.map((productValue, index) => {
                                return <MDBDropdownItem link key={index} onClick={loadBySiteUrl} href={productValue.siteurl} className={productValue.menuclass}>{productValue.title}</MDBDropdownItem>
                            })}
                          </ul>
                        </MDBDropdown>
                      </MDBCol>
                    
                    </MDBBreadcrumb>
                    <ErrorHandler error={fetchError} />
                  </MDBCol>
                </MDBRow>

              </div>
            </div>

            <div className="row">
              <div className="products cards front_packages_preview w-100">
                {
                  isLoading && (
                    <>
                      <div className="loader-dynamic">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    </>
                  )
                }
                { 
                  !isLoading &&
                  currentPackages.length !== 0 &&
                  currentPackages.map((packageData, index) => {
                    return <ProductWrapper value={packageData} key={packageData._id} delay={(index / 10)} />
                  })
                }
              </div>
              {
                !isLoading &&
                allPackages.length > itemsPrPage() && (
                  <Pagination
                    items={allPackages}
                    itemsPerPage={itemsPrPage()}
                    itemOffset={itemOffset}
                    handlePageClick={handlePageClick}
                  />
                )
              }
            </div>
          </div>
        </div>
      <Footer />
    </>
  )
};

export default GameServers;